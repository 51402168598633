import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useTheme } from '../../contexts/ThemeContext';
import logo from '../../assets/logo.png';

const Header = () => {
  const { user, isAuthenticated } = useAuth0();
  const [isUserMenuOpen, setIsUserMenuOpen] = React.useState(false);
  const { isDarkMode, toggleDarkMode } = useTheme();


  return (
    <header className={`flex justify-between items-center bg-[#00baff] dark:bg-gray-800 text-white py-2 px-4 z-30 relative h-16`}>
      <div className="flex items-center">
        <a href="" className="flex items-center">
          <img src={logo} alt="Logo" className="h-8 w-auto mr-2" />
          <h1 className="text-xl font-bold hidden sm:inline">Vision2</h1>
        </a>
      </div>

      <div className="flex items-center">
        <button
          onClick={toggleDarkMode}
          className="mr-4 p-2 rounded-full bg-gray-200 dark:bg-gray-600 text-gray-800 dark:text-white"
        >
          {isDarkMode ? '☀️' : '🌙'}
        </button>

        <div className="relative">
          <button
            onClick={() => setIsUserMenuOpen(!isUserMenuOpen)}
            className="flex items-center focus:outline-none"
          >
            {isAuthenticated ? (
              <>
                <span className="mr-2 text-sm hidden md:inline">{user.name}</span>
                <svg
                  className="w-5 h-5"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                </svg>
              </>
            ) : (
              <span>Login</span>
            )}
          </button>
          {isUserMenuOpen && (
            <div className="absolute right-0 mt-2 w-48 bg-white dark:bg-gray-700 rounded-md overflow-hidden shadow-xl z-10">
              {isAuthenticated ? (
                <div className="py-2">
                  <a href="/profile" className="block px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-600">
                    Profile
                  </a>
                  <a href="/logout" className="block px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-600">
                    Logout
                  </a>
                </div>
              ) : (
                <div className="py-2">
                  <a href="/login" className="block px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-600">
                    Login
                  </a>
                  <a href="/signup" className="block px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-600">
                    Sign Up
                  </a>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </header>
  );
};


export default Header;
