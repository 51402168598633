
export const ConversationContactStatusNames = {
  '100': 'Ready To Use',
  '101': 'Loaded for BL Test',

  '110': 'Already in Active Conversation',

  '150': 'Imported With Error',
  '151': 'Invalid Email',
  '152': 'Duplicate',
  '153': 'Already Unsubscribed',
  '154': 'Already Bounced Email',
  '155': 'Already Existing WIP',

  '200': 'Ignore',
  '201': 'Bounced Back (hard)',
  '211': 'Bounced Back (soft)',

  '11': 'Outbound',
  '12': 'Customer Out Of Office',
  '13': 'Outbound No Reply',

  '21': 'Interested',
  '22': 'Contact Later',

  '31': 'Not Interested',
  '32': 'Existing Customer',
  '33': 'Opt Out Via Link',
  '34': 'Opt Out Via Email',

  '310': 'Purchased Elsewhere',
  '311': 'Customer Sold Car',
  '312': 'Booked Elsewhere',
  '313': 'Update Details',
  '314': 'Existing Prospect',
  '315': 'Customer Data Incorrect',
  '316': 'Vehicle Data Incorrect',
  '317': 'Interest In Vehicle Purchase',
  '318': 'Interest In Vehicle Selling',

  '320': 'Bereavement',
  '321': 'Direct Engagement',

  '331': 'Details Request',
  '332': 'Request To Change Booking',
  '333': 'Interested In Finance',

  '400': 'Complaint',
  '401': 'Polite',
  '402': 'Praise',

  '500': 'Referral'
};
/**
 *
 *
 * @param {string | number} code
 * @returns {string}
 */
export const getProductName = (code) => {
  return ConversationContactStatusNames[code] || 'Unknown Product';
};
