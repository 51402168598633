import React from 'react';
import MonacoEditor from 'react-monaco-editor';
import PropTypes from 'prop-types';

const ModernMonacoEditor = ({
  width = '100%',
  height = '100%',
  language = 'javascript',
  theme = 'vs-light',
  value = '',
  onChange = () => {},
  options = {},
  editorDidMount = () => {},
  editorWillMount = () => {},
  context = undefined
}) => {
  const defaultOptions = {
    minimap: { enabled: false },
    automaticLayout: true,
    scrollBeyondLastLine: false,
    fontSize: 14,
    scrollbar: {
      vertical: 'visible',
      horizontal: 'visible',
    },
    ...options
  };

  return (
    <MonacoEditor
      width={width}
      height={height}
      language={language}
      theme={theme}
      value={value}
      onChange={onChange}
      options={defaultOptions}
      editorDidMount={editorDidMount}
      editorWillMount={editorWillMount}
      context={context}
    />
  );
};

ModernMonacoEditor.propTypes = {
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  language: PropTypes.string,
  theme: PropTypes.oneOf(['vs-dark', 'vs-light', 'hc-black']),
  value: PropTypes.string,
  onChange: PropTypes.func,
  editorDidMount: PropTypes.func,
  editorWillMount: PropTypes.func,
  options: PropTypes.shape({
    readOnly: PropTypes.bool,
    minimap: PropTypes.shape({
      enabled: PropTypes.bool
    }),
    automaticLayout: PropTypes.bool,
    scrollBeyondLastLine: PropTypes.bool,
    fontSize: PropTypes.number,
    scrollbar: PropTypes.shape({
      vertical: PropTypes.string,
      horizontal: PropTypes.string
    }),
    lineNumbers: PropTypes.oneOf(['on', 'off', 'relative', 'interval']),
    wordWrap: PropTypes.oneOf(['off', 'on', 'wordWrapColumn', 'bounded']),
    folding: PropTypes.bool,
    renderWhitespace: PropTypes.oneOf(['none', 'boundary', 'all']),
    tabSize: PropTypes.number
  }),
  context: PropTypes.any
};

export default ModernMonacoEditor;