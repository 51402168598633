import React, { useState, useEffect } from 'react';
import CopyButton from '../../../components/misc/CopyButton.jsx'
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useTheme } from '../../../contexts/ThemeContext';
import { X, Plus } from 'lucide-react';
import { grabToken } from '../../../utils/api';
import LoadingSpinner from '../../../components/LoadingSpinner.js';
import ErrorPage from '../../Error/ErrorPage.js';

const BranchDetailPage = () => {
  const [branch, setBranch] = useState({
    id: '',
    name: '',
    reference: '',
    address1: '',
    address2: '',
    town: '',
    county: '',
    country: '',
    zipPostCode: '',
    accountcode: '',
    tel: '',
    codes: [],
    state: 0,
    distributionEmails: [],
    consentAgreementId: null,
    consentAgreementName: null,
    company: { id: '', name: '' }
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [activeTab, setActiveTab] = useState('details');
  const { id } = useParams();
  const navigate = useNavigate();
  const { isDarkMode } = useTheme();
  const [newCode, setNewCode] = useState('');
  const location = useLocation();


  useEffect(() => {
    if (id) {
      fetchBranchDetails(id);
    }
  }, [id]);

  const fetchBranchDetails = async (branchId) => {
    setLoading(true);
    setError(null);
    try {
      const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
      const token = grabToken();
      const response = await axios.get(`${apiBaseUrl}/Branches/${branchId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
        },
      });
      setBranch(response.data.result);
    } catch (error) {
      setError(error.response ? `Error: ${error.response.status} - ${error.response.data}` : error.message);
    } finally {
      setLoading(false);
    }
  };

  const validateField = (name, value) => {
    switch (name) {
      case 'name':
        return value.trim() === '' ? 'Name is required' : '';
      case 'address1':
        return value.trim() === '' ? 'Address is required' : '';
      case 'town':
        return value.trim() === '' ? 'Town is required' : '';
      case 'county':
        return value.trim() === '' ? 'County is required' : '';
      case 'zipPostCode':
        return value.trim() === '' ? 'Zip/Post Code is required' : '';
      case 'tel':
        return value.trim() === '' ? 'Telephone number is required' :
               value.replace(/\D/g, '').length < 6 ? 'Telephone number must have at least 6 digits' : '';
      default:
        return '';
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    let newValue;

    if (name === 'tel') {
      newValue = value;
    } else if (type === 'checkbox') {
      newValue = checked;
    } else if (name === 'state') {
      newValue = parseInt(value, 10);
    } else {
      newValue = value;
    }

    setBranch(prev => ({
      ...prev,
      [name]: newValue
    }));

    const fieldError = validateField(name, newValue);
    setErrors(prev => ({
      ...prev,
      [name]: fieldError
    }));
  };

  const handleDeleteCode = (indexToDelete) => {
    setBranch(prevBranch => ({
      ...prevBranch,
      codes: prevBranch.codes.filter((_, index) => index !== indexToDelete)
    }));
  };

  const handleAddCode = (e) => {
    e.preventDefault();
    if (newCode.trim() !== '') {
      setBranch(prevBranch => ({
        ...prevBranch,
        codes: [...prevBranch.codes, newCode.trim()]
      }));
      setNewCode('');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = Object.keys(branch).reduce((acc, key) => {
      const fieldError = validateField(key, branch[key]);
      if (fieldError) {
        acc[key] = fieldError;
      }
      return acc;
    }, {});

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    try {
      const token = grabToken();

      // Prepare the data for the API
      const branchData = {
        id: branch.id,
        companyId: branch.company.id,
        consentAgreementId: branch.consentAgreementId,
        name: branch.name,
        reference: branch.reference,
        address1: branch.address1,
        address2: branch.address2 || "",
        county: branch.county,
        country: branch.country,
        codes: branch.codes.filter(code => code.trim() !== ""),
        tel: branch.tel,
        town: branch.town,
        zipPostCode: branch.zipPostCode,
        accountcode: branch.accountcode,
      };

      const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;


      const response = await axios.put(
        `${apiBaseUrl}/Branches/${branch.id}`,
        branchData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );


      if (response.status === 200) {
        setIsEditing(false);
        fetchBranchDetails(branch.id);
      } else {
        throw new Error('Failed to update branch');
      }
    } catch (error) {
      console.error('Error updating branch:', error);
      setError(error.response
        ? `Error updating branch: ${error.response.status} - ${JSON.stringify(error.response.data)}`
        : error.message
      );
    }
  };

  const handleBackClick = () => {
    if (location.state && location.state.from) {
      navigate(location.state.from);
    } else if (window.history.length > 2) {
      navigate(-1);
    } else {
      navigate('/client-admin/branches');
    }
  };

  const handleEditClick = (e) => {
    e.preventDefault();
    setIsEditing(true);
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    fetchBranchDetails(branch.id);
  };

  const inputClass = `w-full px-3 py-2 rounded-md shadow-sm focus:ring focus:ring-opacity-50 ${
    isDarkMode
      ? 'bg-gray-700 border-gray-600 focus:border-blue-300 focus:ring-blue-200 text-white'
      : 'border-gray-300 focus:border-indigo-300 focus:ring-indigo-200'
  }`;

  const errorClass = 'text-red-500 text-sm mt-1';

  const tabClass = (tabName) => `
    px-4 py-2 font-medium text-sm rounded-t-lg
    ${activeTab === tabName
      ? isDarkMode
        ? 'bg-gray-700 text-white'
        : 'bg-white text-blue-600'
      : isDarkMode
      ? 'bg-gray-600 text-gray-300 hover:bg-gray-500'
      : 'bg-gray-200 text-gray-600 hover:bg-gray-300'
    }
  `;

  if (error) return <ErrorPage isDarkMode={isDarkMode} errorMessage={error.message} errorCode={error.code} />
  if (loading) return <LoadingSpinner />

  const renderField = (label, name, type = 'text', step, options = []) => {
    if (name === 'tel') {
      return (
        <div className="grid grid-cols-3 gap-4 items-center py-3 border-b border-gray-200 dark:border-gray-600 last:border-b-0">
          <label htmlFor={name} className="text-sm font-medium text-gray-700 dark:text-gray-300">
            {label}
          </label>
          <div className="col-span-2">
            <PhoneInput
              country={'gb'}
              onlyCountries={['gb', 'ae']}
              value={branch[name]}
              onChange={(phone) => handleInputChange({ target: { name, value: phone } })}
              disabled={!isEditing}
              inputProps={{
                name: 'tel',
                id: 'tel',
                className: `${inputClass} w-full pl-[60px] ${errors[name] ? 'border-red-500' : ''}`,
              }}
              containerClass="w-full"
              buttonClass={`${isDarkMode ? 'bg-gray-700 border-gray-600' : ''} absolute top-0 left-0`}
              dropdownClass={`${isDarkMode ? 'bg-gray-700 text-white' : ''} !w-[300px]`}
            />
            {errors[name] && <span className={errorClass}>{errors[name]}</span>}
          </div>
        </div>
      );
    } else if (name === "accountcode") {
            return (
              <div className="grid grid-cols-3 gap-4 items-center py-3 border-b border-gray-200 dark:border-gray-600 last:border-b-0">
                <label htmlFor={name} className="text-sm font-medium text-gray-700 dark:text-gray-300">
                  {label}
                </label>
                <div className="col-span-2 flex items-center space-x-2">
                  <input
                    type={type}
                    id={name}
                    name={name}
                    value={branch[name] || ""}
                    onChange={handleInputChange}
                    disabled={!isEditing}
                    className={`${inputClass} flex-grow ${errors[name] ? "border-red-500" : ""}`}
                  />
                  <CopyButton textToCopy={branch[name]} />
                </div>
                {errors[name] && <span className={errorClass}>{errors[name]}</span>}
              </div>
        );
      } else if (name === 'codes') {
      const validCodes = branch.codes ? branch.codes.filter(code => code.trim() !== "") : [];
      return (
        <div className="grid grid-cols-3 gap-4 items-center py-3 border-b border-gray-200 dark:border-gray-600 last:border-b-0">
          <label className="text-sm font-medium text-gray-700 dark:text-gray-300">
            {label}
          </label>
          <div className="col-span-2">
            <div className="flex flex-wrap gap-2 mb-2">
              {validCodes.map((code, index) => (
                <span
                  key={index}
                  className={`px-3 py-2 rounded-md text-base flex items-center ${
                    isDarkMode
                      ? 'bg-blue-600 text-white'
                      : 'bg-blue-100 text-blue-800'
                  }`}
                >
                  {code}
                  {isEditing && (
                    <button
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        handleDeleteCode(index);
                      }}
                      className="ml-2 focus:outline-none"
                    >
                      <X size={16} />
                    </button>
                  )}
                </span>
              ))}
            </div>
            {isEditing && (
              <div className="flex items-center">
                <input
                  type="text"
                  value={newCode}
                  onChange={(e) => setNewCode(e.target.value)}
                  className={`${inputClass} mr-2`}
                  placeholder="Add new code"
                />
                <button
                  type="button"
                  onClick={handleAddCode}
                  className={`px-3 py-2 rounded-md ${
                    isDarkMode
                      ? 'bg-green-600 text-white hover:bg-green-700'
                      : 'bg-green-100 text-green-800 hover:bg-green-200'
                  }`}
                >
                  <Plus size={20} />
                </button>
              </div>
            )}
          </div>
        </div>
      );
    }

    return (
      <div className="grid grid-cols-3 gap-4 items-center h-[53px] border-b border-gray-200 dark:border-gray-600 last:border-b-0">
        <label htmlFor={name} className="text-sm font-medium text-gray-700 dark:text-gray-300">
          {label}
        </label>
        <div className="col-span-2">
          {type === 'select' ? (
            <select
              id={name}
              name={name}
              value={branch[name] || ''}
              onChange={handleInputChange}
              disabled={!isEditing}
              className={`${inputClass} ${errors[name] ? 'border-red-500' : ''}`}
            >
              <option value="">Select {label}</option>
              {options.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          ) : (
            <input
              type={type}
              id={name}
              name={name}
              value={name === 'company.name' ? branch.company.name : branch[name] || ''}
              onChange={handleInputChange}
              disabled={!isEditing || name === 'company.name'}
              step={step}
              className={`${inputClass} ${errors[name] ? 'border-red-500' : ''}`}
            />
          )}
          {errors[name] && <span className={errorClass}>{errors[name]}</span>}
        </div>
      </div>
    );
  };

  const renderDetailsTab = () => (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div className="bg-white dark:bg-gray-700 shadow-lg rounded-lg overflow-hidden">
        <div className="px-4 py-5 sm:px-6 border-b border-gray-200 dark:border-gray-600">
          <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white">
            Branch Information
          </h3>
        </div>
        <div className="px-4 py-5 sm:p-6 space-y-4">
          {renderField('Company', 'company.name')}
          {renderField('Name', 'name')}
          {renderField('Reference', 'reference')}
          {renderField('Address 1', 'address1')}
          {/* {renderField('Address 2', 'address2')} */}
          {renderField('Town', 'town')}
          {renderField('County', 'county')}
          {renderField('Country', 'country', 'select', null, ['uk', 'ae'])}
          {renderField('Zip/Post Code', 'zipPostCode')}
          {renderField('Account Code', 'accountcode')}
          {renderField('Telephone', 'tel')}
          {/* {renderField('State', 'state', 'number')} */}
          {renderField('Codes', 'codes')}
        </div>
      </div>
      <div className="flex justify-between">
        {isEditing ? (
          <>
            <button
              type="submit"
              className={`px-6 py-2 rounded-md font-bold focus:outline-none focus:ring-2 focus:ring-offset-2 transition-colors duration-200 ${
                isDarkMode
                  ? 'bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 text-white'
                  : 'bg-blue-500 hover:bg-blue-600 focus:ring-blue-400 text-white'
              }`}
            >
              Save Changes
            </button>
            <button
              type="button"
              onClick={handleCancelEdit}
              className={`px-6 py-2 rounded-md font-bold focus:outline-none focus:ring-2 focus:ring-offset-2 transition-colors duration-200 ${
                isDarkMode
                  ? 'bg-gray-600 hover:bg-gray-700 focus:ring-gray-500 text-white'
                  : 'bg-gray-300 hover:bg-gray-400 focus:ring-gray-400 text-gray-800'
              }`}
            >
              Cancel
            </button>
          </>
        ) : (
          <button
            type="button"
            onClick={handleEditClick}
            className={`px-6 py-2 rounded-md font-bold focus:outline-none focus:ring-2 focus:ring-offset-2 transition-colors duration-200 ${
              isDarkMode
                ? 'bg-yellow-600 hover:bg-yellow-700 focus:ring-yellow-500 text-white'
                : 'bg-yellow-500 hover:bg-yellow-600 focus:ring-yellow-400 text-white'
            }`}
          >
            Edit Branch
          </button>
        )}
      </div>
    </form>
  );

  const renderLogsTab = () => (
    <div className="bg-white dark:bg-gray-700 shadow-lg rounded-lg overflow-hidden">
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white mb-4">
          Branch Logs
        </h3>
        <p className="text-sm text-gray-600 dark:text-gray-300">
          This is where you would display logs related to the branch. You can fetch and display log data here.
        </p>
      </div>
    </div>
  );

  return (
    <div className={`flex flex-col ${isDarkMode ? 'dark bg-gray-800 text-white' : 'bg-gray-100 text-gray-800'}`}>
      <div className="container mx-auto p-6">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl font-bold">Branch Details | {branch.name}</h1>
          <button
            type="button"
            onClick={handleBackClick}
            className={`px-4 py-2 rounded-md text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 transition-colors duration-200 ${
              isDarkMode
                ? 'bg-gray-600 hover:bg-gray-700 focus:ring-gray-500 text-white'
                : 'bg-gray-300 hover:bg-gray-400 focus:ring-gray-400 text-gray-800'
            }`}
          >
            Back
          </button>
        </div>

        <div className="mb-4 border-b border-gray-200 dark:border-gray-700">
          <ul className="flex flex-wrap -mb-px">
            <li className="mr-2">
              <button
                className={tabClass('details')}
                onClick={() => setActiveTab('details')}
              >
                Details
              </button>
            </li>
            <li className="mr-2">
              <button
                className={tabClass('logs')}
                onClick={() => setActiveTab('logs')}
              >
                Logs
              </button>
            </li>
          </ul>
        </div>

        {activeTab === 'details' ? renderDetailsTab() : renderLogsTab()}
      </div>
    </div>
  );
};

export default BranchDetailPage;
