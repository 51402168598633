// src/hooks/usePrompt.js
import { useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Modal } from 'antd';

export default function usePrompt(message, when = true) {
  const navigate = useNavigate();
  const currentLocation = useLocation();

  const handleBlockedNavigation = useCallback(
    (nextLocation) => {
      if (currentLocation.pathname !== nextLocation.pathname && when) {
        Modal.confirm({
          title: 'Unsaved Changes',
          content: message,
          okText: 'Leave',
          cancelText: 'Stay',
          onOk: () => {
            navigate(nextLocation.pathname);
          }
        });
        return false;
      }
      return true;
    },
    [navigate, currentLocation, message, when]
  );

  useEffect(() => {
    if (!when) return undefined;

    window.history.pushState(null, '', currentLocation.pathname);

    const handlePopState = (event) => {
      event.preventDefault();
      const nextLocation = { pathname: window.location.pathname };
      handleBlockedNavigation(nextLocation);
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [when, currentLocation, handleBlockedNavigation]);

  return;
}
