import React, { useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import ErrorPage from '../Error/ErrorPage.js';

const CallbackPage = () => {
    const { handleRedirectCallback, isLoading, error } = useAuth0();

    useEffect(() => {
        const processAuthentication = async () => {
            const result = await handleRedirectCallback();
            if (result && result.appState && result.appState.returnTo) {
                window.location.href = result.appState.returnTo; // Redirect to intended page
            } else {
                window.location.href = '/';
            }
        }
        processAuthentication();
    }, [handleRedirectCallback]);



    if (isLoading) {
        return <div>Loading...</div>;
    }


    if (error) return <ErrorPage errorMessage={error.message} errorCode={error.code} />

    return <div>Redirecting...</div>;
};

export default CallbackPage;
