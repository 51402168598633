import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { grabToken } from '../../../utils/api';
import { useTheme } from '../../../contexts/ThemeContext';
import { format, parseISO } from 'date-fns';
import PropTypes from 'prop-types';

const API_CONFIG = {
  baseURL: process.env.REACT_APP_API_BASE_URL,
  endpoints: {
    products: '/Products',
  },
};

const Switch = ({ isOn, handleToggle, disabled }) => {
    const { isDarkMode } = useTheme();
    return (
      <label className="flex items-center cursor-pointer">
        <div className="relative">
          <input
            type="checkbox"
            className="hidden"
            checked={isOn}
            onChange={handleToggle}
            disabled={disabled}
          />
          <div className={`toggle__line w-10 h-6 rounded-full shadow-inner ${
            isOn ? (isDarkMode ? 'bg-blue-600' : 'bg-blue-500') : (isDarkMode ? 'bg-gray-600' : 'bg-gray-300')
          } ${disabled ? 'opacity-50' : ''}`}></div>
          <div className={`toggle__dot absolute w-4 h-4 bg-white rounded-full shadow inset-y-1 left-1 ${
            isOn ? 'transform translate-x-full' : ''
          }`}></div>
        </div>
        <div className={`ml-3 text-sm font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
          {isOn ? 'Enabled' : 'Disabled'}
        </div>
      </label>
    );
  };

const ProductDetailPage = () => {
  const [product, setProduct] = useState({
    id: '',
    name: '',
    goal: '',
    labels: '',
    aiEnabled: false,
    createdByUser: { id: '', name: '' },
    updatedByUser: { id: '', name: '' },
    createdDate: '',
    updatedDate: '',
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const { isDarkMode } = useTheme();

  useEffect(() => {
    if (id) {
      fetchProductDetails(id);
    }
  }, [id]);

  const fetchProductDetails = async (productId) => {
    setLoading(true);
    setError(null);
    try {
      const token = grabToken();
      const response = await axios.get(`${API_CONFIG.baseURL}${API_CONFIG.endpoints.products}/${productId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
        },
      });
      setProduct(response.data.result);
    } catch (error) {
      setError(error.response ? `Error: ${error.response.status} - ${error.response.data}` : error.message);
    } finally {
      setLoading(false);
    }
  };

  const validateField = (name, value) => {
    switch (name) {
      case 'name':
        return value.trim() === '' ? 'Name is required' : '';
      case 'goal':
        return value.trim() === '' ? 'Goal is required' : '';
      default:
        return '';
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    let newValue = type === 'checkbox' ? checked : value;

    setProduct(prev => ({
      ...prev,
      [name]: newValue
    }));

    const fieldError = validateField(name, newValue);
    setErrors(prev => ({
      ...prev,
      [name]: fieldError
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = Object.keys(product).reduce((acc, key) => {
      const fieldError = validateField(key, product[key]);
      if (fieldError) {
        acc[key] = fieldError;
      }
      return acc;
    }, {});

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    try {
      const token = grabToken();

      const productData = {
        id: product.id,
        name: product.name,
        goal: product.goal,
        labels: product.labels,
        aiEnabled: product.aiEnabled,
      };

      const response = await axios.put(
        `${API_CONFIG.baseURL}${API_CONFIG.endpoints.products}/${product.id}`,
        productData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200) {
        setIsEditing(false);
        fetchProductDetails(product.id);
      } else {
        throw new Error('Failed to update product');
      }
    } catch (error) {
      console.error('Error updating product:', error);
      setError(error.response
        ? `Error updating product: ${error.response.status} - ${JSON.stringify(error.response.data)}`
        : error.message
      );
    }
  };

  const handleEditClick = (e) => {
    e.preventDefault();
    setIsEditing(true);
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    fetchProductDetails(product.id);
  };

  const formatDate = (dateString) => {
    if (!dateString || dateString === "0001-01-01T00:00:00") return 'N/A';
    try {
      return format(parseISO(dateString), 'dd MMM yyyy HH:mm');
    } catch (error) {
      console.error('Error formatting date:', error);
      return dateString;
    }
  };

  const renderMetadataField = (label, user, date) => (
    <div className="grid grid-cols-3 gap-4 items-center py-3 border-b border-gray-200 dark:border-gray-600 last:border-b-0">
      <label className="text-sm font-medium text-gray-700 dark:text-gray-300">
        {label}
      </label>
      <div className="col-span-2">
        <p className="text-sm text-gray-900 dark:text-gray-100">
          {user.name} <span className="text-gray-500">on</span> {formatDate(date)}
        </p>
      </div>
    </div>
  );

  const inputClass = `w-full px-3 py-2 rounded-md shadow-sm focus:ring focus:ring-opacity-50 ${
    isDarkMode
      ? 'bg-gray-700 border-gray-600 focus:border-blue-300 focus:ring-blue-200 text-white'
      : 'border-gray-300 focus:border-indigo-300 focus:ring-indigo-200'
  }`;

  const errorClass = 'text-red-500 text-sm mt-1';

  if (loading) return <div className="text-center">Loading...</div>;
  if (error) return <div className="text-red-500 font-bold text-center">{error}</div>;

const renderField = (label, name, type = 'text') => {
  return (
    <div className={`grid grid-cols-4 gap-4 items-center ${type === 'textarea' ? 'h-auto py-3' : 'h-[53px]'} border-b border-gray-200 dark:border-gray-600 last:border-b-0`}>
      <label htmlFor={name} className="text-sm font-medium text-gray-700 dark:text-gray-300">
        {label}
      </label>
      <div className="col-span-3">
        {type === 'switch' ? (
          <Switch
            isOn={product[name]}
            handleToggle={() => handleInputChange({ target: { name, type: 'checkbox', checked: !product[name] } })}
            disabled={!isEditing}
          />
        ) : type === 'textarea' ? (
          <textarea
            id={name}
            name={name}
            value={product[name] || ''}
            onChange={handleInputChange}
            disabled={!isEditing}
            className={`${inputClass} ${name === 'goal' || name === 'labels' ? 'h-40' : 'h-32'} resize-none ${errors[name] ? 'border-red-500' : ''}`}
          />
        ) : (
          <input
            type={type}
            id={name}
            name={name}
            value={product[name] || ''}
            onChange={handleInputChange}
            disabled={!isEditing || name === 'id' || name.includes('User') || name.includes('Date') || name === 'dataTable'}
            className={`${inputClass} ${errors[name] ? 'border-red-500' : ''}`}
          />
        )}
        {errors[name] && <span className={errorClass}>{errors[name]}</span>}
      </div>
    </div>
  );
};


  return (
    <div className={`flex flex-col ${isDarkMode ? 'dark bg-gray-800 text-white' : 'bg-gray-100 text-gray-800'}`}>
      <div className="container mx-auto p-6">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl font-bold">Product Details</h1>
          <button
            type="button"
            onClick={() => navigate('/internal-admin/products')}
            className={`px-4 py-2 rounded-md text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 transition-colors duration-200 ${
              isDarkMode
                ? 'bg-gray-600 hover:bg-gray-700 focus:ring-gray-500 text-white'
                : 'bg-gray-300 hover:bg-gray-400 focus:ring-gray-400 text-gray-800'
            }`}
          >
            Back to Products
          </button>
        </div>

        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="bg-white dark:bg-gray-700 shadow-lg rounded-lg overflow-hidden">
            <div className="px-4 py-5 sm:px-6 border-b border-gray-200 dark:border-gray-600">
              <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white">
                Product Information
              </h3>
            </div>
            <div className="px-4 py-5 sm:p-6 space-y-4">
              {renderField('Name', 'name')}
              {renderField('AI Enabled', 'aiEnabled', 'switch')}
              {renderField('Goal', 'goal', "textarea")}
              {renderField('Labels', 'labels', "textarea")}
              {renderMetadataField('Created By', product.createdByUser, product.createdDate)}
              {renderMetadataField('Updated By', product.updatedByUser, product.updatedDate)}
            </div>
          </div>
          <div className="flex justify-between">
            {isEditing ? (
              <>
                <button
                  type="submit"
                  className={`px-6 py-2 rounded-md font-bold focus:outline-none focus:ring-2 focus:ring-offset-2 transition-colors duration-200 ${
                    isDarkMode
                      ? 'bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 text-white'
                      : 'bg-blue-500 hover:bg-blue-600 focus:ring-blue-400 text-white'
                  }`}
                >
                  Save Changes
                </button>
                <button
                  type="button"
                  onClick={handleCancelEdit}
                  className={`px-6 py-2 rounded-md font-bold focus:outline-none focus:ring-2 focus:ring-offset-2 transition-colors duration-200 ${
                    isDarkMode
                      ? 'bg-gray-600 hover:bg-gray-700 focus:ring-gray-500 text-white'
                      : 'bg-gray-300 hover:bg-gray-400 focus:ring-gray-400 text-gray-800'
                  }`}
                >
                  Cancel
                </button>
              </>
            ) : (
              <button
                type="button"
                onClick={handleEditClick}
                className={`px-6 py-2 rounded-md font-bold focus:outline-none focus:ring-2 focus:ring-offset-2 transition-colors duration-200 ${
                  isDarkMode
                    ? 'bg-yellow-600 hover:bg-yellow-700 focus:ring-yellow-500 text-white'
                    : 'bg-yellow-500 hover:bg-yellow-600 focus:ring-yellow-400 text-white'
                }`}
              >
                Edit Product
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

Switch.propTypes = {
  isOn: PropTypes.bool.isRequired,
  handleToggle: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default ProductDetailPage;
