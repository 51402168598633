import React, { useEffect, useState } from 'react';
import { queryDocuments } from '../../utils/ApiFetch.js';
import { useParams } from 'react-router-dom';
import Header from '../../components/Layouts/HeaderWithoutSideBar.jsx';
import ErrorPage from '../Error/ErrorPage';
import { useTheme } from '../../contexts/ThemeContext';
import LoadingSpinner from '../../components/LoadingSpinner.js';
import PropTypes from 'prop-types';
import CustomModal from '../../components/CustomModal.jsx';
import formatDate from '../../utils/formatDate.jsx';

function getDateColor(dueDateString) {
  if (!dueDateString) return 'bg-gray-500';

  const dueDate = new Date(dueDateString);
  const today = new Date();
  const timeDiff = dueDate - today;
  const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

  if (daysDiff > 30) {
    return 'bg-green-500';
  } else if (daysDiff > 7) {
    return 'bg-orange-500';
  } else if (daysDiff >= 0) {
    return 'bg-red-500';
  } else {
    return 'bg-gray-500';
  }
}

function getDaysTillDate(dueDateString) {
  if (!dueDateString) return null;
  const dueDate = new Date(dueDateString);
  const today = new Date();
  const timeDiff = dueDate - today;
  const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
  return daysDiff;
}

const DataTable = ({ vehicleData }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [modalTitle, setModalTitle] = useState('');
  const [openMenuId, setOpenMenuId] = useState(null);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });

  const cleanseRequests = vehicleData?.cleanseRequests || [];

  useEffect(() => {
    function handleClickOutside(event) {
      if (openMenuId && !event.target.closest('.dropdown-container')) {
        setOpenMenuId(null);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [openMenuId]);

  const handleButtonClick = (event, requestId) => {
    event.stopPropagation();
    const rect = event.currentTarget.getBoundingClientRect();
    setDropdownPosition({
      top: rect.bottom + window.scrollY,
      right: window.innerWidth - rect.right,
    });
    setOpenMenuId(openMenuId === requestId ? null : requestId);
  };

  const handleOptionClick = (request, option) => {
    let content;
    let title;

    switch (option) {
      case 'details':
        title = 'Vehicle Details';
        content = (
          <div className="space-y-4">
            <div className="grid grid-cols-2 gap-4">
              <p className="font-medium">VRM:</p>
              <p>{request.vrm || 'N/A'}</p>
              <p className="font-medium">Vehicle FK:</p>
              <p>{request.vehicleFK || 'N/A'}</p>
              <p className="font-medium">Status:</p>
              <p>{request.status || 'N/A'}</p>
              <p className="font-medium">Branch Name:</p>
              <p>{request.branchName || 'N/A'}</p>
              <p className="font-medium">Source Inspection Date:</p>
              <p>{formatDate(request.sourceMotDate) || 'N/A'}</p>
              <p className="font-medium">Date of Last Work:</p>
              <p>{formatDate(request.dateOfLastWork) || 'N/A'}</p>
              <p className="font-medium">Linked Owned Customer:</p>
              <p>{request.linkedOwnerCustomer || 'N/A'}</p>
              <p className="font-medium">Manufacturer:</p>
              <p>{request.manufacturer || 'N/A'}</p>
              <p className="font-medium">Vehicle Selling Date:</p>
              <p>{formatDate(request.vehicleSellingDate) || 'N/A'}</p>
            </div>
          </div>
        );
        break;

      case 'government':
        title = 'Government Inspection Details';
        content = (
          <div className="space-y-4">
            <div className="grid grid-cols-2 gap-4">
              <p className="font-medium">Source Inspection Date:</p>
              <p>{formatDate(request.sourceMotDate)}</p>
              <p className="font-medium">Current Inspection Date:</p>
              <p>{formatDate(request.currentMOTDate)}</p>
              <p className="font-medium">Inspection Update Required:</p>
              <p>{request.motUpdate ? 'Yes' : 'No'}</p>
            </div>
          </div>
        );
        break;

      case 'regDate':
        title = 'Registration Date';
        content = (
          <div className="space-y-4">
            <div className="grid grid-cols-2 gap-4">
              <p className="font-medium">Registration Date:</p>
              <p>{formatDate(request.registrationDate) || 'N/A'}</p>
              <p className="font-medium">First UK Registration Date:</p>
              <p>{formatDate(request.dateFirstRegisteredUk)}</p>
              <p className="font-medium">Vehicle Selling Date:</p>
              <p>{formatDate(request.vehicleSellingDate) || 'N/A'}</p>
              <p className="font-medium">Date of Last Work:</p>
              <p>{formatDate(request.dateOfLastWork) || 'N/A'}</p>
            </div>
          </div>
        );
        break;

      default:
        content = <p>No data available</p>;
        title = 'Information';
    }

    setModalTitle(title);
    setModalContent(content);
    setIsModalOpen(true);
    setOpenMenuId(null);
  };

  return (
    <>
      <div className="mt-6">
        <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-4">
          <h2 className="bg-blue-500 dark:bg-blue-700 text-white px-4 py-2 rounded-t-lg font-semibold mb-4">
            Cleanse Requests
          </h2>

          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
              <thead>
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                    VRM
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                    Current VRM
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                    Source Inspection
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                    Current Inspection Date
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                    Updated
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                    Options
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                {cleanseRequests.map((request) => (
                  <tr key={request.id}>
                    <td className="px-6 py-4 whitespace-nowrap">{request.vrm || 'N/A'}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{request.currentVrm || 'N/A'}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{formatDate(request.sourceMotDate)}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{formatDate(request.currentMOTDate)}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{formatDate(request.updated)}</td>
                    <td className="px-6 py-4 whitespace-nowrap relative dropdown-container">
                      <button
                        onClick={(e) => handleButtonClick(e, request.id)}
                        className="p-1 rounded-full hover:bg-gray-100 dark:hover:bg-gray-700"
                      >
                        <svg
                          className="h-5 w-5"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                          />
                        </svg>
                      </button>
                      {openMenuId === request.id && (
                        <div
                          className="fixed w-48 bg-white dark:bg-gray-800 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-50"
                          style={{
                            top: `${dropdownPosition.top}px`,
                            right: `${dropdownPosition.right}px`,
                          }}
                          onClick={(e) => e.stopPropagation()}
                        >
                          <div className="py-1">
                            <button
                              className="block px-4 py-2 text-sm w-full text-left hover:bg-gray-100 dark:hover:bg-gray-700"
                              onClick={() => handleOptionClick(request, 'details')}
                            >
                              Details
                            </button>
                            <button
                              className="block px-4 py-2 text-sm w-full text-left hover:bg-gray-100 dark:hover:bg-gray-700"
                              onClick={() => handleOptionClick(request, 'government')}
                            >
                              Government Inspection
                            </button>
                            <button
                              className="block px-4 py-2 text-sm w-full text-left hover:bg-gray-100 dark:hover:bg-gray-700"
                              onClick={() => handleOptionClick(request, 'regDate')}
                            >
                              Registration Date
                            </button>
                          </div>
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <CustomModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">{modalTitle}</h2>
          <button
            onClick={() => setIsModalOpen(false)}
            className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
          >
            <span className="sr-only">Close</span>
            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <div className="mt-4">{modalContent}</div>
      </CustomModal>
    </>
  );
};

DataTable.propTypes = {
  vehicleData: PropTypes.shape({
    cleanseRequests: PropTypes.array,
  }),
};

const VehicleContent = () => {
  const { id } = useParams();
  const { isDarkMode } = useTheme();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState({
    identifications: [],
    odometer: [],
    registration: [],
    inspection: [],
    service: [],
    topCards: {
      cleanseRequest: 'N/A',
      nextInspection: 'N/A',
      nextService: 'N/A',
      inspectionDateColor: 'bg-gray-500',
      serviceDateColor: 'bg-gray-500',
    },
    cleanseData: null,
  });

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      try {
        const container = 'vehicles';
        const lowerCaseId = id?.toLowerCase() || '';
        const query = `SELECT * FROM c WHERE c.systemVehicleId='${lowerCaseId}'`;
        const response = await queryDocuments(container, query);

        const reference_id = lowerCaseId.split('_')[1];

        if (!response || response.length === 0) {
          throw new Error('No Document data found for ID.');
        }

        const data0 = response[0] || null;
        const vehicleData = response.find((response) => response.recordType === 'vehicle');
        const vehicle = vehicleData?.vehicle;

        const nextServiceDateString = vehicle?.serviceData?.dueDate;
        const serviceDateColor = getDateColor(nextServiceDateString);
        const daysTillNextService = getDaysTillDate(nextServiceDateString);

        const nextInspectionDateString = vehicle?.inspections?.find(
          (inspection) => inspection?.type === 'GOVERNMENT'
        )?.dueDate;
        const inspectionDateColor = getDateColor(nextInspectionDateString);
        const daysTillNextInspection = getDaysTillDate(nextInspectionDateString);

        const cleanseRequestCount = Array.isArray(data0?.cleanseRequests)
          ? data0.cleanseRequests.length
          : data0?.cleanseRequests
          ? Object.keys(data0.cleanseRequests).length
          : 0;

        setData({
          crm: [
            { id: 1, attribute: 'Company', value: data0?.company?.name || 'N/A' },
            { id: 2, attribute: 'System', value: data0?.system?.name || 'N/A' },
            { id: 3, attribute: 'Reference', value: reference_id || 'N/A' },
          ],
          identifications: [
            { id: 1, attribute: 'VIN', value: vehicle?.identification?.vin || 'N/A' },
            {
              id: 2,
              attribute: 'License Plate',
              value:
                vehicle?.identification?.licensePlate ||
                data0?.cleanseRequests[0]?.dvla?.registration ||
                'N/A',
            },
            {
              id: 3,
              attribute: 'Make',
              value:
                vehicle?.baseDetails?.make || data0?.cleanseRequests[0]?.dvla?.make || 'N/A',
            },
            {
              id: 4,
              attribute: 'Model',
              value:
                vehicle?.baseDetails?.model || data0?.cleanseRequests[0]?.dvla?.model || 'N/A',
            },
            {
              id: 5,
              attribute: 'Model Year',
              value:
                vehicle?.baseDetails?.modelYear ||
                data0?.cleanseRequests[0]?.dvla?.manufactureYear ||
                'N/A',
            },
          ],
          odometer: [
            { id: 1, attribute: 'Unit', value: vehicle?.odometerReading?.unit || 'N/A' },
            { id: 2, attribute: 'Value', value: vehicle?.odometerReading?.value || 'N/A' },
          ],
          registration: [
            {
              id: 1,
              attribute: 'Registration Date',
              value:
                formatDate(data0?.cleanseRequests[0]?.dvla?.registrationDate) ||
                formatDate(vehicle?.registrationData?.registrationDate),
            },
            {
              id: 2,
              attribute: 'First Registration Date',
              value:
                formatDate(data0?.cleanseRequests[0]?.dateFirstRegisteredUk) ||
                formatDate(vehicle?.registrationData?.firstRegistrationDate),
            },
          ],
          inspection: vehicle?.inspections || [],
          service: vehicle?.serviceData
            ? [
                {
                  id: 1,
                  attribute: 'Due Date',
                  value: formatDate(vehicle?.serviceData?.dueDate),
                },
                {
                  id: 2,
                  attribute: 'Due Odometer Reading',
                  value: vehicle?.serviceData?.dueOdometerReading?.value || 'N/A',
                },
                {
                  id: 3,
                  attribute: 'Last Date',
                  value: formatDate(vehicle?.serviceData?.lastDate),
                },
                {
                  id: 4,
                  attribute: 'Last Odometer Reading',
                  value: vehicle?.serviceData?.lastOdometerReading?.value || 'N/A',
                },
              ]
            : [],
          topCards: {
            cleanseRequest: cleanseRequestCount,
            nextInspection: daysTillNextInspection !== null ? daysTillNextInspection : 'N/A',
            nextService: daysTillNextService !== null ? daysTillNextService : 'N/A',
            inspectionDateColor: inspectionDateColor,
            serviceDateColor: serviceDateColor,
          },
          cleanseData: data0,
        });
      } catch (err) {
        setError('No Document found for this ID');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  if (error) return <ErrorPage errorMessage={error} showNote={true} isDarkMode={isDarkMode} showBackButton={false} />;

  return (
    <div
      className={`min-h-screen ${
        isDarkMode ? 'bg-gray-900 text-white' : 'bg-gray-100 text-gray-900'
      }`}
    >
      <Header />
      {/* Main Content */}
      <div className="container mx-auto px-2 py-3">
        <div className="flex flex-col md:flex-row gap-4">
          {/* Left Panel */}
          <div className="w-full md:w-1/3 flex flex-col gap-4">
            {/* CRM Details */}
            <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-2">
              <h2 className="bg-blue-500 dark:bg-blue-700 text-white px-2 py-1 rounded-t-lg font-semibold">
                CRM Details
              </h2>
              <div className="p-2">
                {loading ? (
                  <LoadingSpinner />
                ) : (
                  <table className="min-w-full">
                    <tbody>
                      {data.crm.map((crm) => (
                        <tr
                          key={crm.id}
                          className="border-b border-gray-200 dark:border-gray-700"
                        >
                          <td className="px-2 py-1 font-medium">{crm.attribute}</td>
                          <td className="px-2 py-1">{crm.value}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>

            {/* Identifications */}
            <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-2">
              <h2 className="bg-blue-500 dark:bg-blue-700 text-white px-2 py-1 rounded-t-lg font-semibold">
                Identifications
              </h2>
              <div className="p-2">
                {loading ? (
                  <LoadingSpinner />
                ) : (
                  <table className="min-w-full">
                    <tbody>
                      {data.identifications.map((item) => (
                        <tr
                          key={item.id}
                          className="border-b border-gray-200 dark:border-gray-700"
                        >
                          <td className="px-2 py-1 font-medium">{item.attribute}</td>
                          <td className="px-2 py-1">{item.value}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>

            {/* Odometer */}
            <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-2">
              <h2 className="bg-blue-500 dark:bg-blue-700 text-white px-2 py-1 rounded-t-lg font-semibold">
                Odometer
              </h2>
              <div className="p-2">
                {loading ? (
                  <LoadingSpinner />
                ) : (
                  <table className="min-w-full">
                    <tbody>
                      {data.odometer.map((item) => (
                        <tr
                          key={item.id}
                          className="border-b border-gray-200 dark:border-gray-700"
                        >
                          <td className="px-2 py-1 font-medium">{item.attribute}</td>
                          <td className="px-2 py-1">{item.value}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>

            {/* Registration */}
            <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-2">
              <h2 className="bg-blue-500 dark:bg-blue-700 text-white px-2 py-1 rounded-t-lg font-semibold">
                Registration
              </h2>
              <div className="p-2">
                {loading ? (
                  <LoadingSpinner />
                ) : (
                  <table className="min-w-full">
                    <tbody>
                      {data.registration.map((item) => (
                        <tr
                          key={item.id}
                          className="border-b border-gray-200 dark:border-gray-700"
                        >
                          <td className="px-2 py-1 font-medium">{item.attribute}</td>
                          <td className="px-2 py-1">{item.value}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>

            {/* Inspection */}
            <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-2">
              <h2 className="bg-blue-500 dark:bg-blue-700 text-white px-2 py-1 rounded-t-lg font-semibold">
                Inspection
              </h2>
              <div className="p-2">
                {loading ? (
                  <LoadingSpinner />
                ) : data.inspection.length > 0 ? (
                  <table className="min-w-full">
                    <thead>
                      <tr>
                        <th className="px-2 py-1 text-left">Type</th>
                        <th className="px-2 py-1 text-left">Last Date</th>
                        <th className="px-2 py-1 text-left">Due Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.inspection.map((item, index) => (
                        <tr
                          key={index}
                          className="border-b border-gray-200 dark:border-gray-700"
                        >
                          <td className="px-2 py-1">{item.type || 'N/A'}</td>
                          <td className="px-2 py-1">{formatDate(item.lastDate)}</td>
                          <td className="px-2 py-1">{formatDate(item.dueDate)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <p>No inspection data available.</p>
                )}
              </div>
            </div>

            {/* Service */}
            <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-2">
              <h2 className="bg-blue-500 dark:bg-blue-700 text-white px-2 py-1 rounded-t-lg font-semibold">
                Service
              </h2>
              <div className="p-2">
                {loading ? (
                  <LoadingSpinner />
                ) : data.service.length > 0 ? (
                  <table className="min-w-full">
                    <tbody>
                      {data.service.map((item) => (
                        <tr
                          key={item.id}
                          className="border-b border-gray-200 dark:border-gray-700"
                        >
                          <td className="px-2 py-1 font-medium">{item.attribute}</td>
                          <td className="px-2 py-1">{item.value}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <p>No service data available.</p>
                )}
              </div>
            </div>
          </div>
          {/* Right Panel */}
          <div className="w-full md:w-2/3 flex flex-col gap-6">
            {/* Top Cards */}
            <div className="flex flex-col md:flex-row gap-6">
              {/* Cleanse Request */}
              <div className="flex-1 bg-white dark:bg-gray-800 shadow rounded-lg p-4">
                <div className="flex items-center">
                  <div className="bg-green-500 text-white rounded-full h-12 w-12 flex items-center justify-center font-bold mr-4">
                    {data.topCards.cleanseRequest}
                  </div>
                  <div>
                    <h3 className="text-lg font-semibold">Cleanse Request</h3>
                    <p className="text-sm text-gray-500 dark:text-gray-300">Requests</p>
                  </div>
                </div>
              </div>

              {/* Next Inspection */}
              <div className="flex-1 bg-white dark:bg-gray-800 shadow rounded-lg p-4">
                <div className="flex items-center">
                  <div
                    className={`${data.topCards.inspectionDateColor} text-white rounded-full h-12 w-12 flex items-center justify-center font-bold mr-4`}
                  >
                    {data.topCards.nextInspection !== 'N/A' ? data.topCards.nextInspection : ''}
                  </div>
                  <div>
                    <h3 className="text-lg font-semibold">Next Inspection</h3>
                    <p className="text-sm text-gray-500 dark:text-gray-300">
                      {data.topCards.nextInspection !== 'N/A'
                        ? 'days left'
                        : 'No upcoming inspection'}
                    </p>
                  </div>
                </div>
              </div>

              {/* Next Service */}
              <div className="flex-1 bg-white dark:bg-gray-800 shadow rounded-lg p-4">
                <div className="flex items-center">
                  <div
                    className={`${data.topCards.serviceDateColor} text-white rounded-full h-12 w-12 flex items-center justify-center font-bold mr-4`}
                  >
                    {data.topCards.nextService !== 'N/A' ? data.topCards.nextService : ''}
                  </div>
                  <div>
                    <h3 className="text-lg font-semibold">Next Service</h3>
                    <p className="text-sm text-gray-500 dark:text-gray-300">
                      {data.topCards.nextService !== 'N/A'
                        ? 'days left'
                        : 'No upcoming service'}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <DataTable vehicleData={data.cleanseData} />
          </div>
        </div>
      </div>

      <footer className="fixed bottom-0 left-0 right-0 bg-gray-200 dark:bg-gray-700 text-center p-2 text-sm z-50">
        © {new Date().getFullYear()} AI-Assistant. All rights reserved.
      </footer>
    </div>
  );
};

CustomModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default VehicleContent;

