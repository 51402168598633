import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const routeTitles = {
  // Main Routes
  '/': 'Home | Vision',
  '/settings': 'Settings | Vision',

  // Client Admin Routes
  '/client-admin/client': 'Clients | Vision',
  '/client-admin/clients/:id': 'Client Details | Vision',
  '/client-admin/systems': 'Systems | Vision',
  '/client-admin/systems/:id': 'System Details | Vision',
  '/client-admin/systems/add': 'Add System | Vision',
  '/client-admin/branches': 'Branches | Vision',
  '/client-admin/branches/:id': 'Branch Details | Vision',
  '/client-admin/branches/add': 'Add Branch | Vision',
  '/client-admin/virtual-assistants': 'Virtual Assistants | Vision',
  '/client-admin/virtual-assistants/:id': 'Assistant Details | Vision',
  '/client-admin/customer/:contactId': 'Customer Details | Vision',
  '/client-admin/conversation/:id': 'Conversation Details | Vision',

  // Internal Admin Routes
  '/internal-admin/products': 'Products | Vision',
  '/internal-admin/products/:id': 'Product Details | Vision',
  '/internal-admin/AIML': 'AI/ML Models | Vision',
  '/internal-admin/aiml-models/:id': 'AI/ML Details | Vision',
  '/internal-admin/providers': 'Providers | Vision',
  '/internal-admin/providers/:id': 'Provider Details | Vision',

  // Reporting Routes
  '/customer-reporting/:id': 'Customer Report | Vision',
  '/vehicle-reporting/:id': 'Vehicle Report | Vision',
  '/email-reporting/:contactId': 'Email Report | Vision',

  // Public Routes
  '/unauthorized': 'Unauthorized | Vision',
  '/error': 'Error | Vision',
  '/callback': 'Authentication | Vision',
  '/logout-callback': 'Logging Out | Vision'
};

const getPageTitle = (pathname) => {
  if (routeTitles[pathname]) {
    return routeTitles[pathname];
  }

  const pathParts = pathname.split('/');
  const routes = Object.keys(routeTitles);

  for (const route of routes) {
    const routeParts = route.split('/');

    if (routeParts.length === pathParts.length) {
      const matches = routeParts.every((part, index) => {
        return part.startsWith(':') || part === pathParts[index];
      });

      if (matches) {
        return routeTitles[route];
      }
    }
  }

  return 'Vision';
};

const TitleManager = () => {
  const location = useLocation();

  useEffect(() => {
    const title = getPageTitle(location.pathname);
    document.title = title;
  }, [location]);

  return null;
};

export default TitleManager;
