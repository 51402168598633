import axios from 'axios';
import qs from 'qs';

const base_url = process.env.REACT_APP_API_BASE_URL;

export const fetchClientDetails = async (clientId, token) => {
  const response = await axios.get(`${base_url}/Companies/${clientId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  });
  return response.data.result;
};

export const fetchSystems = async (clientId, token) => {
  const response = await axios.get(`${base_url}/Systems/getForCompany/${clientId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  });
  return response.data.result;
};

/**
 * Function to query documents
 * @param {string} container - The container name (e.g., 'customers')
 * @param {string} query - The SQL-like query string
 * @returns {Promise<Object>} - The response data
 */
export const queryDocuments = async (container, query) => {
  try {
    const reporting_api_url = process.env.REACT_APP_REPORTING_API_URL
    const response = await axios.get(`${reporting_api_url}/QueryDocuments`, {
      params: {
        container,
        query,
      },
      headers: {
        'x-functions-key': process.env.REACT_APP_REPORTING_API_KEY,
        'Content-Type': 'application/json',
      }
    });
    return response.data;
  } catch (error) {
    console.error('API QueryDocuments Error:', error);
    throw error;
  }
};

export const fetchBranches = async (clientId, token) => {
  const response = await axios.get(`${base_url}/Branches/selectList/${clientId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  });
  return response.data.result;
};

export const fetchVaData = async (clientId, token) => {
  const response = await axios.get(`${base_url}/VirtualAssistants/${clientId}/filter`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  });
  return response.data.result.list;
};

export const fetchHolidays = async (clientId, token) => {
  const response = await axios.get(`${base_url}/ConversationHolidays/${clientId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  });
  return response.data.result;
};

export const fetchMatchedConversations = async (clientId, token) => {
  const params = new URLSearchParams({
    onlyNew: 'true',
    sortPropertyName: 'conversation.name',
    sortAscending: 'true',
    pageSize: '30',
    pageNumber: '0',
    clientCompanyId: clientId.toString(),
  });

  const response = await axios.get(`${base_url}/Messages/incoming/extended?${params.toString()}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  });
  return response.data.result.list;
};

export const fetchOutboxMessages = async (clientId, token) => {
  const params = new URLSearchParams({
    sortAscending: 'true',
    pageSize: '30',
    pageNumber: '0',
    clientCompanyId: clientId.toString(),
    isSent: 'false',
  });

  const response = await axios.get(`${base_url}/Messages/outgoing/filter?${params.toString()}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  });
  return response.data.result.list;
};

export const fetchCustomersData = async (contactId, token) => {
  const response = await axios.get(`${base_url}/ConversationContacts/${contactId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  });
  return response.data.result;
};

export const fetchConversation = async (conversationId, token) => {
  const response = await axios.get(`${base_url}/Conversations/${conversationId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  });
  return response.data.result;
};

export const updateConversation = async (conversationId, conversationData, token) => {
  const response = await axios.put(
    `${base_url}/Conversations/${conversationId}`,
    conversationData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    }
  );
  return response.data;
};

export const fetchUnknownMessages = async (clientId, token) => {
  const params = new URLSearchParams({
    onlyNew: 'true',
    sortPropertyName: 'sentOnUtc',
    sortAscending: 'false',
    pageSize: '30',
    pageNumber: '0',
    clientCompanyId: clientId.toString(),
    updateTrigger: '0',
    "states[]": '0',
  });

  const response = await axios.get(`${base_url}/Messages/incoming/unknown/filter?${params.toString()}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  });
  return response.data.result;
};

export const fetchMessageContent = async (contactId, token) => {
  const url = `${base_url}/Messages/${contactId}/filter`;
  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  });
  return response.data.result;
};

export const fetchAiProcessingData = async (clientId, token) => {
  try {
    const response = await axios.get(
      `${base_url}/Messages/incoming/filter`,
      {
        params: {
          isSent: false,
          sortPropertyName: 'createdDate',
          sortAscending: true,
          pageSize: 100,
          pageNumber: 0,
          clientCompanyId: clientId,
          'states[]': [8], // Make sure this matches the expected state value
        },
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        paramsSerializer: params => {
          // Use a library like qs to properly serialize array parameters
          return qs.stringify(params, { arrayFormat: 'brackets' })
        }
      }
    );

    return response.data;
  } catch (error) {
    console.error('Error fetching AI processing data:', error);
    
    // Handle specific error cases
    if (error.response?.status === 400) {
      console.error('Bad Request Details:', error.response.data);
      throw new Error('Invalid request parameters for AI processing data');
    }
    
    throw new Error(error.message || 'Failed to fetch AI processing data');
  }
};

export const updateClient = async (clientData, token) => {
  const response = await axios.put(
    `${base_url}/Companies`,
    clientData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    }
  );
  return response.data;
};
