import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useTheme } from '../../contexts/ThemeContext';
import logo from '../../assets/logo.png';
import PropTypes from 'prop-types';


const Header = ({ toggleSidebar }) => {
  const { user, isAuthenticated } = useAuth0();
  const [isUserMenuOpen, setIsUserMenuOpen] = React.useState(false);
  const { isDarkMode, toggleDarkMode } = useTheme();


  const handleLogoClick = (e) => {
    e.preventDefault();
    window.location.href = '/';
  };

  return (
    <div className={`flex flex-col h-screen font-sans ${isDarkMode ? 'dark' : ''}`}>
      {/* Top Bar */}
      <header className="bg-[#00baff] dark:bg-gray-800 text-white py-2 px-2 z-30 relative h-16">
        <div className="container mx-auto h-full flex justify-between items-center">
          {/* Left side - Logo and Toggle */}
          <div className="flex items-center">
            <button className="p-1 hover:bg-blue-700 dark:hover:bg-gray-700 rounded mr-2" onClick={toggleSidebar}>
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
              </svg>
            </button>
            <a href="/" onClick={handleLogoClick} className="flex items-center">
              <img src={logo} alt="Logo" className="h-8 w-auto mr-2" />
              <h1 className="text-xl font-bold hidden sm:inline">Vision</h1>
            </a>
          </div>

          {/* Right side - Night Mode Toggle and User Menu */}
          <div className="flex items-center">
            <button
              onClick={toggleDarkMode}
              className="mr-4 p-2 rounded-full bg-gray-200 dark:bg-gray-600 text-gray-800 dark:text-white"
            >
              {isDarkMode ? '☀️' : '🌙'}
            </button>

            <div className="relative">
            <button
              onClick={() => setIsUserMenuOpen(!isUserMenuOpen)}
              className="flex items-center focus:outline-none"
            >
              {isAuthenticated ? (
                <>
                  <span className="mr-2 text-sm hidden md:inline">{user.name}</span>
                  <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                  </svg>
                </>
              ) : (
                <span>Login</span>
              )}
            </button>
            {isUserMenuOpen && (
              <div className="absolute right-0 mt-2 w-48 bg-white dark:bg-gray-700 rounded-md overflow-hidden shadow-xl z-10">
                {/* User menu content */}
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  </div>
  );
};

Header.propTypes = {
  toggleSidebar: PropTypes.func.isRequired,
};

export default Header;
