import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import PropTypes from 'prop-types';
import { jwtDecode } from 'jwt-decode';

const ProtectedRoute = ({ requiredPermissions = [], requiredRoles = [], children }) => {
  const { isAuthenticated, isLoading, getAccessTokenSilently, user } = useAuth0();
  const [userPermissions, setUserPermissions] = React.useState([]);
  const [userRoles, setUserRoles] = React.useState([]);

  React.useEffect(() => {
    const getUserAccess = async () => {
      if (isAuthenticated && user) {
        try {
          const accessToken = await getAccessTokenSilently();
          const decodedToken = jwtDecode(accessToken);
          const permissions = decodedToken.permissions || [];
          const userUrl = `${process.env.REACT_APP_USER_URL}/roles`;
          const roles = user[userUrl] || [];
          setUserPermissions(permissions);
          setUserRoles(roles);

        } catch (error) {
          console.error('Error getting access token:', error);
        }
      }
    };
    getUserAccess();
  }, [isAuthenticated, getAccessTokenSilently, user]);

  if (isLoading || (requiredPermissions.length > 0 && userPermissions.length === 0) ||
      (requiredRoles.length > 0 && userRoles.length === 0)) {
    return <div>Loading...</div>;
  }

  if (requiredPermissions.length === 0 && requiredRoles.length === 0) {
    return children;
  }

  const hasRequiredPermissions = requiredPermissions.length === 0 ||
    requiredPermissions.every(permission => userPermissions.includes(permission));

  const hasRequiredRoles = requiredRoles.length === 0 ||
    requiredRoles.some(role => userRoles.includes(role));

  if (!hasRequiredPermissions || !hasRequiredRoles) {
    return <Navigate to="/unauthorized" />;
  }

  return children;
};

ProtectedRoute.propTypes = {
  requiredPermissions: PropTypes.arrayOf(PropTypes.string),
  requiredRoles: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.node.isRequired,
};

export default ProtectedRoute;
