import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import "./ErrorPage.css";

const ErrorPage = ({ errorCode, errorMessage, isDarkMode, showNote, showBackButton }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const dynamicErrorCode = state?.errorCode || errorCode;
  const dynamicErrorMessage = state?.errorMessage || errorMessage;

  const handleBackToHome = () => {
    navigate("/");
  };

  const getErrorMessage = () => {
    if (dynamicErrorMessage) return dynamicErrorMessage;
    switch (dynamicErrorCode) {
      case 404:
        return "The page you’re looking for doesn’t exist.";
      case 500:
        return "Internal server error. Please try again later.";
      default:
        return "An unexpected error has occurred.";
    }
  };

  return (
    <div className={`error-page ${isDarkMode ? "dark-mode" : ""}`}>
      <div className="error-container" role="alert">
        <h1 className="error-title">Oops! Something went wrong.</h1>
        <p className="error-message">{getErrorMessage()}</p>
        {dynamicErrorCode && <p className="error-code">Error code: {dynamicErrorCode}</p>}
        {showNote && (
          <p className="note-message">
            Note: Please Come Back Soon We&apos;re Building Data
          </p>
        )}
        {showBackButton && (
          <button onClick={handleBackToHome} className="back-button">
            Go back to homepage
          </button>
        )}
        <p className="contact-support">
          If this error persists, please contact eddie@lacewoods.com
        </p>
      </div>
   </div>
  );
};

ErrorPage.propTypes = {
  errorCode: PropTypes.number,
  errorMessage: PropTypes.string,
  isDarkMode: PropTypes.bool,
  showNote: PropTypes.bool,
  showBackButton: PropTypes.bool,
};

ErrorPage.defaultProps = {
  isDarkMode: false,
  showNote: false,
  showBackButton: true,
};

export default ErrorPage;

