import ErrorPage from '../../../pages/Error/ErrorPage';
import React, { useState, useEffect, lazy, Suspense, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTheme } from '../../../contexts/ThemeContext';
import {
  fetchClientDetails,
  fetchSystems,
  fetchBranches,
  fetchVaData,
  fetchHolidays,
  fetchMatchedConversations,
  fetchUnknownMessages,
  fetchAiProcessingData,
  fetchOutboxMessages,
} from '../../../utils/ApiFetch';
import ClientHeader from '../../../components/ClientAdminComponents/ClientHeader';
import ClientTabs from '../../../components/ClientAdminComponents/ClientTabs';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { grabToken } from '../../../utils/api';

const DetailsTab = lazy(() => import('../../../components/ClientAdminComponents/DetailsTab'));
const LogsTab = lazy(() => import('../../../components/ClientAdminComponents/LogTab'));
const AiInfoTab = lazy(() => import('../../../components/ClientAdminComponents/AiInfoTab'));
const VaTab = lazy(() => import('../../../components/ClientAdminComponents/VaTab'));
const HolidaysTab = lazy(() => import('../../../components/ClientAdminComponents/HolidaysTab'));
const InboxTab = lazy(() => import('../../../components/ClientAdminComponents/InboxTabs'));
const OutboxTab = lazy(() => import('../../../components/ClientAdminComponents/OutboxTab'));

const ClientDetailsPage = () => {
  const [client, setClient] = useState({});
  const [systems, setSystems] = useState([]);
  const [branches, setBranches] = useState([]);
  const [vaData, setVaData] = useState([]);
  const [holidays, setHolidays] = useState([]);
  const [matchedConversations, setMatchedConversations] = useState([]);
  const [aiProcessingData, setAiProcessingData] = useState([]);
  const [outboundMessages, setOutboundMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('details');
  const [tabLoading, setTabLoading] = useState(false);

  const [unknownMessages, setUnknownMessages] = useState({
    list: [],
    totalElementsCount: 0,
  });

  const { id } = useParams();
  const navigate = useNavigate();
  const { isDarkMode } = useTheme();

  const fetchEssentialData = useCallback(async () => {
    setLoading(true);
    try {
      const token = grabToken();
      const [clientData, systemsData, branchesData] = await Promise.all([
        fetchClientDetails(id, token),
        fetchSystems(id, token),
        fetchBranches(id, token),
      ]);
      console.log(clientData);
      setClient(clientData);
      setSystems(systemsData);
      setBranches(branchesData);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      fetchEssentialData();
    }
  }, [id, fetchEssentialData]);

  const fetchVaDataForTab = useCallback(async () => {
    try {
      const token = grabToken();
      const data = await fetchVaData(id, token);
      setVaData(data);
    } catch (error) {
      setError(error.message);
    }
  }, [id]);

  const fetchHolidaysData = useCallback(async () => {
    try {
      const token = grabToken();
      const data = await fetchHolidays(id, token);
      setHolidays(data);
    } catch (error) {
      setError(error.message);
    }
  }, [id]);

  const fetchInboxData = useCallback(async () => {
    setTabLoading(true);
    try {
      const token = grabToken();
      if (!token) {
        throw new Error('Authentication token not found');
      }

      const [matchedConversationsData, unknownMessagesData, aiProcessingDataResult] = await Promise.all([
        fetchMatchedConversations(id, token).catch(err => {
          console.error('Error fetching matched conversations:', err);
          return [];
        }),
        fetchUnknownMessages(id, token).catch(err => {
          console.error('Error fetching unknown messages:', err);
          return { list: [], totalElementsCount: 0 };
        }),
        fetchAiProcessingData(id, token).catch(err => {
          console.error('Error fetching AI processing data:', err);
          return [];
        }),
      ]);

      setMatchedConversations(Array.isArray(matchedConversationsData) ? matchedConversationsData : []);
      setUnknownMessages(unknownMessagesData || { list: [], totalElementsCount: 0 });
      setAiProcessingData(Array.isArray(aiProcessingDataResult) ? aiProcessingDataResult : []);
    } catch (error) {
      console.error('Error in fetchInboxData:', error);
      setError(error.message || 'Error loading inbox data');
    } finally {
      setTabLoading(false);
    }
  }, [id]);

  const fetchOutboxData = useCallback(async () => {
    try {
      const token = grabToken();
      const data = await fetchOutboxMessages(id, token);
      setOutboundMessages(data);
    } catch (error) {
      setError(error.message);
    }
  }, [id]);

  const fetchTabData = useCallback(async (tab) => {
    setTabLoading(true);
    try {
      switch (tab) {
        case 'va':
          await fetchVaDataForTab();
          break;
        case 'holidays':
          await fetchHolidaysData();
          break;
        case 'inbox':
          await fetchInboxData();
          break;
        case 'outbox':
          await fetchOutboxData();
          break;
        default:
          break;
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setTabLoading(false);
    }
  }, [fetchVaDataForTab, fetchHolidaysData, fetchInboxData, fetchOutboxData]);

  useEffect(() => {
    fetchTabData(activeTab);
  }, [activeTab, fetchTabData]);

  if (error) return <ErrorPage isDarkMode={isDarkMode} errorMessage={error.message} errorCode={error.code} />
  if (loading) return <LoadingSpinner />

  return (
    <div className={`min-h-screen ${isDarkMode ? 'dark bg-gray-800 text-white' : 'bg-gray-100 text-gray-800'}`}>
      <div className="container mx-auto p-6">
        <ClientHeader navigate={navigate} isDarkMode={isDarkMode} clientName={client.name} />
        <ClientTabs activeTab={activeTab} setActiveTab={setActiveTab} />

        {tabLoading && <LoadingSpinner />}

        <Suspense fallback={<LoadingSpinner />}>
          <div>
            {activeTab === 'details' && (
              <DetailsTab client={client} systems={systems} branches={branches} isDarkMode={isDarkMode} />
            )}
            {activeTab === 'logs' && <LogsTab />}
            {activeTab === 'aiInfo' && (
              <AiInfoTab client={client} setClient={setClient} isDarkMode={isDarkMode} />
            )}
            {activeTab === 'va' && <VaTab vaData={vaData} isDarkMode={isDarkMode} navigate={navigate} />}
            {activeTab === 'holidays' && (
              <HolidaysTab
                holidays={holidays}
                setHolidays={setHolidays}
                isDarkMode={isDarkMode}
                clientId={id}
              />
            )}
            {activeTab === 'inbox' && (
            <InboxTab
              matchedConversations={matchedConversations}
              unknownMessages={unknownMessages}
              fetchAiProcessingData={aiProcessingData}
              isDarkMode={isDarkMode}
            />
            )}
            {activeTab === 'outbox' && (
              <OutboxTab outboundMessages={outboundMessages} isDarkMode={isDarkMode} />
            )}
          </div>
        </Suspense>
      </div>
    </div>
  );
};

export default ClientDetailsPage;

