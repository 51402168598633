import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { grabToken } from '../../../utils/api';
import { format, parseISO } from 'date-fns';
import LoadingSpinner from '../../../components/LoadingSpinner';
import ErrorPage from '../../Error/ErrorPage';

const API_CONFIG = {
  baseURL: process.env.REACT_APP_API_BASE_URL,
  endpoints: {
    models: '/predictionModels',
  },
  pageSize: 100,
};

const AIMLDataPage = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [filters, setFilters] = useState({
      note: '',
      type: '',
      updatedByUser: '',
      updatedDate: '',
    });
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(12);
    const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const token = grabToken();
      const response = await axios.get(`${API_CONFIG.baseURL}${API_CONFIG.endpoints.models}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
        },
        params: {
          pageSize: API_CONFIG.pageSize
        }
      });

      if (response.data && response.data.result && Array.isArray(response.data.result.list)) {
        setData(response.data.result.list);
      } else {
        setError('Received data is not in the expected format');
      }
    } catch (error) {
      setError(error.response ? `Error: ${error.response.status} - ${error.response.data}` : error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleFilterChange = (property, value) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [property]: value
    }));
    setCurrentPage(1);
  };

  const formatDate = (dateString) => {
    if (!dateString) return '';
    try {
      return format(parseISO(dateString), 'dd MMM yyyy HH:mm');
    } catch (error) {
      console.error('Error formatting date:', error);
      return dateString;
    }
  };

  const getTypeDisplay = (type) => {
    switch (type) {
      case 15:
        return "AI Reply Prediction";
      case 16:
        return "AI Labels Prediction";
      default:
        return type;
    }
  };

  const filteredData = data.filter(item =>
    Object.keys(filters).every(key => {
      const filterValue = filters[key].toLowerCase();
      if (key === 'updatedByUser') {
        return item.updatedByUser?.name?.toLowerCase().includes(filterValue) ?? false;
      }
      if (key === 'updatedDate') {
        return formatDate(item[key]).toLowerCase().includes(filterValue);
      }
      if (key === 'type') {
        return getTypeDisplay(item[key]).toLowerCase().includes(filterValue);
      }
      return item[key]?.toString().toLowerCase().includes(filterValue) ?? false;
    })
  );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleRowClick = (itemId) => {
    navigate(`/internal-admin/aiml-models/${itemId}`);
  };

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  const getPageNumbers = (currentPage, totalPages) => {
    const pageNumbers = [];
    const visiblePages = 3;

    if (totalPages <= visiblePages + 2) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      pageNumbers.push(1);

      let start = Math.max(currentPage - Math.floor(visiblePages / 2), 2);
      let end = Math.min(start + visiblePages - 1, totalPages - 1);

      if (end - start < visiblePages - 1) {
        start = Math.max(end - visiblePages + 1, 2);
      }

      if (start > 2) {
        pageNumbers.push('...');
      }

      for (let i = start; i <= end; i++) {
        pageNumbers.push(i);
      }

      if (end < totalPages - 1) {
        pageNumbers.push('...');
      }

      pageNumbers.push(totalPages);
    }

    return pageNumbers;
  };

  if (loading) return <LoadingSpinner />;
  if (error) return <ErrorPage errorCode={error.code} errorMessage={error.message} />;

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const pageNumbers = getPageNumbers(currentPage, totalPages);

  const columns = [
    { key: 'note', header: 'Name' },
    { key: 'type', header: 'Type', render: (value) => getTypeDisplay(value) },
    { key: 'updatedByUser', header: 'Updated By', render: (value) => value?.name },
    { key: 'updatedDate', header: 'Updated Date', render: (value) => formatDate(value) },
  ];

  return (
    <div className="w-full h-full overflow-hidden">
      <div className="flex flex-col h-full">
        <div className="flex justify-between items-center mb-4 px-4">
          <h1 className="text-2xl font-bold text-gray-900 dark:text-white">Internal Admin | AI/ML Models</h1>
        </div>
        <div className="flex-grow overflow-auto">
          <div className="bg-white dark:bg-gray-700 shadow overflow-hidden">
            <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-600">
              <thead className="bg-gray-50 dark:bg-gray-800">
                <tr>
                  {columns.map((column) => (
                    <th key={column.key} className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                      {column.header}
                      <input
                        type="text"
                        placeholder={`Search ${column.header}`}
                        className="block w-full mt-1 p-2 border rounded text-sm dark:bg-gray-700 dark:text-gray-300 dark:border-gray-600"
                        value={filters[column.key] || ''}
                        onChange={(e) => handleFilterChange(column.key, e.target.value)}
                      />
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200 dark:bg-gray-700 dark:divide-gray-600">
                {currentItems.length === 0 ? (
                  <tr>
                    <td colSpan={columns.length} className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-gray-100">
                      No data available
                    </td>
                  </tr>
                ) : (
                  currentItems.map((item) => (
                    <tr
                      key={item.id}
                      onClick={() => handleRowClick(item.id)}
                      className="cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-600"
                    >
                      {columns.map((column) => (
                        <td key={column.key} className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-gray-100">
                          {column.render ? column.render(item[column.key]) : item[column.key]}
                        </td>
                      ))}
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>

        <div className="bg-white dark:bg-gray-700 px-4 py-3 flex items-center justify-between border-t border-gray-200 dark:border-gray-600 sm:px-6">
          <div className="flex-1 flex justify-between sm:hidden">
            <button
              onClick={() => paginate(currentPage - 1)}
              disabled={currentPage === 1}
              className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 dark:bg-gray-700 dark:border-gray-600 dark:text-gray-300 dark:hover:bg-gray-600"
            >
              Previous
            </button>
            <button
              onClick={() => paginate(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 dark:bg-gray-700 dark:border-gray-600 dark:text-gray-300 dark:hover:bg-gray-600"
            >
              Next
            </button>
          </div>

          <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
            <div className="flex items-center space-x-4">
              <div>
                <p className="text-sm text-gray-700 dark:text-gray-300">
                  Showing <span className="font-medium">{indexOfFirstItem + 1}</span> to{' '}
                  <span className="font-medium">{Math.min(indexOfLastItem, filteredData.length)}</span> of{' '}
                  <span className="font-medium">{filteredData.length}</span> results
                </p>
              </div>

              <div className="flex items-center space-x-2">
                <label htmlFor="itemsPerPage" className="text-sm text-gray-700 dark:text-gray-300">
                  Items per page:
                </label>
                <select
                  id="itemsPerPage"
                  value={itemsPerPage}
                  onChange={handleItemsPerPageChange}
                  className="mt-1 block pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md dark:bg-gray-700 dark:text-gray-300 dark:border-gray-600"
                >
                  {[10, 20, 50, 100].map((number) => (
                    <option key={number} value={number}>
                      {number}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div>
              <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                <button
                  onClick={() => paginate(currentPage - 1)}
                  disabled={currentPage === 1}
                  className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 dark:bg-gray-700 dark:border-gray-600 dark:text-gray-300 dark:hover:bg-gray-600"
                >
                  <span className="sr-only">Previous</span>
                  Previous
                </button>

                {pageNumbers.map((pageNumber, index) => (
                  <button
                    key={index}
                    onClick={() => typeof pageNumber === 'number' ? paginate(pageNumber) : null}
                    className={`relative inline-flex items-center px-4 py-2 border text-sm font-medium ${
                      currentPage === pageNumber
                        ? 'z-10 bg-indigo-50 border-indigo-500 text-indigo-600 dark:bg-indigo-700 dark:border-indigo-500 dark:text-white'
                        : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50 dark:bg-gray-700 dark:border-gray-600 dark:text-gray-300 dark:hover:bg-gray-600'
                    } ${pageNumber === '...' ? 'cursor-default' : ''}`}
                    disabled={pageNumber === '...'}
                  >
                    {pageNumber}
                  </button>
                ))}

                <button
                  onClick={() => paginate(currentPage + 1)}
                  disabled={currentPage === totalPages}
                  className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 dark:bg-gray-700 dark:border-gray-600 dark:text-gray-300 dark:hover:bg-gray-600"
                >
                  <span className="sr-only">Next</span>
                  Next
                </button>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AIMLDataPage;
