
export const sanitizeEmailContent = (content) => {
    if (!content) return '';
  
    try {
      const parser = new DOMParser();
      const doc = parser.parseFromString(content, 'text/html');
  
      const unwantedSelectors = [
        '.aiasignature',
        '[data-id="aiasignature"]',
        'img[src*="google-analytics"]',
        'style',
        'meta',
        'link',
        '.CGBannerCode',
        '#yMail_cursorElementTracker',
        'blockquote table',
        'img[src*="mimecast"]',
        '.yiv',
        '[id^="yiv"]',
        '[class^="yiv"]',
        'script',
        'iframe',
      ];
  
      unwantedSelectors.forEach(selector => {
        doc.querySelectorAll(selector).forEach(el => el.remove());
      });
  
      doc.querySelectorAll('*').forEach(el => {
        el.removeAttribute('style');
        el.removeAttribute('class');
        [
          'id', 
          'onclick', 
          'onload', 
          'align', 
          'valign', 
          'width', 
          'height',
          'bgcolor',
          'cellspacing',
          'cellpadding',
        ].forEach(attr => {
          el.removeAttribute(attr);
        });
      });
  
      doc.querySelectorAll('blockquote').forEach(blockquote => {
        blockquote.setAttribute('style', 
          'border-left: 2px solid #e5e7eb; padding-left: 1rem; margin: 1rem 0;'
        );
      });
  
      doc.querySelectorAll('table').forEach(table => {
        table.setAttribute('style', 
          'width: 100%; border-collapse: collapse; margin: 1rem 0;'
        );
      });
  
      let cleanContent = doc.body.innerHTML;
  
      cleanContent = cleanContent
        .replace(/(\r\n|\n|\r)/gm, '')
        .replace(/\s+/g, ' ')
        .replace(/<p>\s*<\/p>/g, '')
        .replace(/<div>\s*<\/div>/g, '')
        .trim();
  
      return cleanContent;
    } catch (error) {
      console.error('Error sanitizing email content:', error);
      return content || 'Error displaying email content';
    }
  };
  
  export const formatEmailPreview = (content, maxLength = 150) => {
    if (!content) return 'No content';
  
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = content;
  
    let textContent = tempDiv.textContent || tempDiv.innerText;
    textContent = textContent.replace(/\s+/g, ' ').trim();
  
    if (textContent.length > maxLength) {
      return textContent.substring(0, maxLength) + '...';
    }
  
    return textContent;
  };
  
  export const parseEmailAddresses = (addresses) => {
    if (!addresses) return [];
    if (typeof addresses === 'string') return [addresses];
    if (Array.isArray(addresses)) return addresses;
    return [];
  };