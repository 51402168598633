import React, { useState } from "react";
import { Clipboard, CheckCircle } from "lucide-react";
import PropTypes from 'prop-types';

function CopyButton({ textToCopy }) {
  const [copySuccess, setCopySuccess] = useState(false);

  const handleCopy = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (textToCopy) {
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          setCopySuccess(true);
          setTimeout(() => setCopySuccess(false), 2000);
        })
        .catch((err) => {
          console.error('Failed to copy:', err);
          setCopySuccess(false);
        });
    }
  };

  return (
    <button
      type="button"
      onClick={handleCopy}
      className="p-2 rounded-md focus:outline-none hover:bg-gray-200 dark:hover:bg-gray-600"
      aria-label={copySuccess ? "Copied!" : "Copy to clipboard"}
    >
      {copySuccess ? (
        <CheckCircle size={20} className="text-green-500" aria-hidden="true" />
      ) : (
        <Clipboard size={20} className="text-gray-700 dark:text-gray-300" aria-hidden="true" />
      )}
    </button>
  );
}

CopyButton.propTypes = {
  textToCopy: PropTypes.string
};

CopyButton.defaultProps = {
  textToCopy: ''
};

export default CopyButton;
