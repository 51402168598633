import React from 'react';
import {
  Users,
  Split,
  FileText,
  Brain,
  User,
  Mail
} from 'lucide-react';

const Dashboard = () => {
  const navItems = [
    { icon: User, label: 'Client', href: 'client-admin/client' },
    { icon: Users, label: 'System', href: 'client-admin/systems' },
    { icon: Split, label: 'Branches', href: 'client-admin/branches' },
    { icon: FileText, label: 'Products', href: 'internal-admin/products' },
    { icon: Brain, label: 'AI/ML Models', href: 'internal-admin/AIML' },
    { icon: Mail, label: 'Providers', href: 'internal-admin/providers' },
  ];

  return (
    <div className="min-h-screen flex flex-col items-center bg-gray-100 dark:bg-gray-800 px-4 py-8 md:px-8">
      <div className="bg-white dark:bg-gray-700 shadow-lg rounded-lg p-6 sm:p-8 md:p-12 w-full max-w-4xl">
        <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-4 text-gray-900 dark:text-white text-center">
          Welcome to Vision
        </h1>
        <p className="text-base sm:text-lg md:text-xl text-gray-700 dark:text-gray-300 text-center">
        </p>
      </div>

      <nav className="bg-white dark:bg-gray-700 shadow-lg rounded-lg p-6 w-full max-w-4xl mt-8">
        <ul className="space-y-2">
          {navItems.map((item, index) => {
            const Icon = item.icon;
            return (
              <li key={index}>
                <a
                  href={item.href}
                  className="flex items-center space-x-3 p-3 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-600 transition-colors duration-200 text-gray-800 dark:text-white"
                >
                  <Icon className="w-5 h-5" />
                  <span>{item.label}</span>
                </a>
              </li>
            );
          })}
        </ul>
      </nav>
    </div>
  );
};

export default Dashboard;
