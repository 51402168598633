import React, { useEffect, useState } from 'react';
import { queryDocuments } from '../../utils/ApiFetch.js';
import { useParams } from 'react-router-dom';
import Header from '../../components/Layouts/HeaderWithoutSideBar.jsx';
import ErrorPage from '../Error/ErrorPage';
import { useTheme } from '../../contexts/ThemeContext';
import LoadingSpinner from '../../components/LoadingSpinner.js';
import formatDate from '../../utils/formatDate.jsx';
import formatCurrency from '../../utils/formatCurrency.jsx';

const CustomerContent = () => {
  const { id } = useParams();
  const { isDarkMode } = useTheme();

  const [loading, setLoading] = useState({
    main: true,
    vehicles: true,
    bookings: true,
    invoices: true,
    orders: true,
  });

  const [error, setError] = useState({
    main: null,
    vehicles: null,
    bookings: null,
    invoices: null,
    orders: null,
  });

  const [data, setData] = useState({
    customerDetails: [],
    individualDetails: [],
    addresses: [],
    communications: [],
    updates: [],
    regional: [],
    vehicles: [],
    serviceBookings: [],
    aftersalesInvoices: [],
    vehicleOrders: [],
  });

  const [totals, setTotals] = useState({
    bookings: 0,
    bookingsValue: 0,
    invoices: 0,
    invoicesValue: 0,
    vehicleOrders: 0,
    vehicleOrdersValue: 0,
  });

  const [tabValue, setTabValue] = useState(0);

  const trimName = (name, maxLength = 20) => {
    return name && name.length > maxLength ? name.substring(0, maxLength) + '...' : name;
  };

  const formatStatus = (status) => {
    const statusMap = {
      B: 'Booked',
      C: 'Completed',
      P: 'Pending',
      X: 'Cancelled',
    };
    return statusMap[status] || status;
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading((prev) => ({ ...prev, main: true }));
      setError((prev) => ({ ...prev, main: null }));

      try {
        const container = 'customers';
        const lowerCaseId = id.toLowerCase();
        const systemGuid = lowerCaseId.split("_")[0];
        console.log("Systemguid", systemGuid);
        const query = `SELECT * FROM c WHERE c.systemCustomerId='${lowerCaseId}'`;
        const response = await queryDocuments(container, query);
        const records = response;
        const customerRecord = records.find((record) => record.recordType === 'customer');
        const aftersalesBookingsRecord = records.find(
          (record) => record.recordType === 'aftersalesBookings'
        );
        const systemCustomer = response[0];
        const reference = id.split('_')[1];
        console.log(response)
        setData((prev) => ({
          ...prev,
          customerDetails: [
            { id: 1, attribute: 'Company', value: systemCustomer?.company?.name || 'N/A' },
            { id: 2, attribute: 'System', value: systemCustomer?.system?.name || 'N/A' },
            { id: 3, attribute: 'Reference', value: reference },
          ],
          individualDetails: [
            { id: 1, attribute: 'Given Name', value: customerRecord?.individual?.givenName || 'N/A' },
            { id: 2, attribute: 'Family Name', value: customerRecord?.individual?.familyName || 'N/A' },
            { id: 3, attribute: 'Initials', value: customerRecord?.individual?.initials || 'N/A' },
            { id: 4, attribute: 'Salutation', value: customerRecord?.individual?.salutation || 'N/A' },
            { id: 5, attribute: 'Title', value: customerRecord?.individual?.title || 'N/A' },
          ],
          addresses: [
            {
              id: 1,
              attribute: 'Street Name',
              value: customerRecord?.addresses?.physical?.streetName || 'N/A',
            },
            {
              id: 2,
              attribute: 'Postcode',
              value: customerRecord?.addresses?.physical?.postalCode || 'N/A',
            },
            {
              id: 3,
              attribute: 'Suburb',
              value: customerRecord?.addresses?.physical?.suburb || 'N/A',
            },
            {
              id: 4,
              attribute: 'City',
              value: customerRecord?.addresses?.physical?.city || 'N/A',
            },
            {
              id: 5,
              attribute: 'Country',
              value: customerRecord?.addresses?.physical?.countryCode || 'N/A',
            },
          ],
          communications: [
            {
              id: 1,
              attribute: 'Mobile',
              value: customerRecord?.communications?.personal?.mobile || 'N/A',
            },
            {
              id: 2,
              attribute: 'Email',
              value: customerRecord?.communications?.personal?.email || 'N/A',
            },
          ],
          updates: [
            {
              id: 1,
              attribute: 'Created',
              value: formatDate(customerRecord?.updateHistory?.created),
            },
            {
              id: 2,
              attribute: 'Modified',
              value: formatDate(customerRecord?.updateHistory?.lastModified),
            },
          ],
          regional: [
            { id: 1, attribute: 'Language Code', value: customerRecord?.languageCode || 'N/A' },
          ],
          vehicles:
            (customerRecord?.vehicles || []).map((v, index) => ({
              id: index + 1,
              relationship: v.relationship || 'N/A',
              description: v.vehicle?.description || 'N/A',
              licensePlate: v.vehicle?.licensePlate || 'N/A',
              vin: v.vehicle?.vin || 'N/A',
              reference: v.vehicle?.reference ? (
                <a
                  href={`/vehicle-reporting/${systemGuid}_${v.vehicle.reference}`}
                  className="text-blue-500 hover:underline"
                >
                  {v.vehicle.reference}
                </a>
              ) : (
                'N/A'
              ),
            })) || [],
        }));

        if (aftersalesBookingsRecord) {
          setData((prev) => ({
            ...prev,
            serviceBookings:
              aftersalesBookingsRecord.bookings?.map((b, index) => ({
                id: index + 1,
                wipNumber: b.wipNumber || 'N/A',
                dateIn: b.dateIn || 'N/A',
                bookingStatus: b.bookingStatus || 'N/A',
                bookingValue: b.bookingValue || 0,
                vrm: b.vrm || 'N/A',
              })) || [],
            aftersalesInvoices:
              aftersalesBookingsRecord.aftersalesInvoices?.map((i, index) => ({
                id: index + 1,
                invoiceNumber: i.invoiceNumber || 'N/A',
                invoiceDate: i.invoiceDate || 'N/A',
                invoiceValue: i.invoiceValue || 0,
                wipNumber: i.wipNumber || 'N/A',
                vrm: i.vrm || 'N/A',
              })) || [],
            vehicleOrders:
              aftersalesBookingsRecord.vehicleOrders?.map((o, index) => ({
                id: index + 1,
                salesOrderNumber: o.salesOrderNumber || 'N/A',
                orderedDate: o.orderedDate || 'N/A',
                totalInvoicesValue: o.totalInvoicesValue || 0,
              })) || [],
          }));

          setTotals({
            bookings: aftersalesBookingsRecord.bookings?.length || 0,
            bookingsValue: aftersalesBookingsRecord.bookings?.reduce(
              (acc, curr) => acc + (curr.bookingValue || 0),
              0
            ),
            invoices: aftersalesBookingsRecord.aftersalesInvoices?.length || 0,
            invoicesValue: aftersalesBookingsRecord.aftersalesInvoices?.reduce(
              (acc, curr) => acc + (curr.invoiceValue || 0),
              0
            ),
            vehicleOrders: aftersalesBookingsRecord.vehicleOrders?.length || 0,
            vehicleOrdersValue: aftersalesBookingsRecord.vehicleOrders?.reduce(
              (acc, curr) => acc + (curr.totalInvoicesValue || 0),
              0
            ),
          });
        }
      } catch (err) {
        console.error('Error fetching customer data:', err);
        setError((prev) => ({ ...prev, main: 'Failed to fetch customer data.' }));
      } finally {
        setLoading((prev) => ({ ...prev, main: false }));
        setLoading((prev) => ({
          ...prev,
          vehicles: false,
          bookings: false,
          invoices: false,
          orders: false,
        }));
      }
    };

    fetchData();
  }, [id]);

  const handleTabChange = (newValue) => {
    setTabValue(newValue);
  };

  const headers = {
    customerDetails: [
      { field: 'attribute', headerName: 'Attribute' },
      { field: 'value', headerName: 'Value' },
    ],
    individualDetails: [
      { field: 'attribute', headerName: 'Attribute' },
      { field: 'value', headerName: 'Value' },
    ],
    addresses: [
      { field: 'attribute', headerName: 'Attribute' },
      { field: 'value', headerName: 'Value' },
    ],
    communications: [
      { field: 'attribute', headerName: 'Attribute' },
      { field: 'value', headerName: 'Value' },
    ],
    updates: [
      { field: 'attribute', headerName: 'Attribute' },
      { field: 'value', headerName: 'Value' },
    ],
    regional: [
      { field: 'attribute', headerName: 'Attribute' },
      { field: 'value', headerName: 'Value' },
    ],
    vehicles: [
      { field: 'relationship', headerName: 'Relationship' },
      { field: 'description', headerName: 'Description' },
      { field: 'licensePlate', headerName: 'License Plate' },
      { field: 'vin', headerName: 'VIN' },
      { field: 'reference', headerName: 'Reference'},
    ],
    bookings: [
      { field: 'wipNumber', headerName: 'Reference' },
      { field: 'dateIn', headerName: 'Date In' },
      { field: 'bookingStatus', headerName: 'Status' },
      { field: 'bookingValue', headerName: 'Value' },
      { field: 'vrm', headerName: 'VRM' },
    ],
    invoices: [
      { field: 'invoiceNumber', headerName: 'Invoice Number' },
      { field: 'invoiceDate', headerName: 'Date' },
      { field: 'invoiceValue', headerName: 'Amount' },
      { field: 'wipNumber', headerName: 'WIP' },
      { field: 'vrm', headerName: 'VRM' },
    ],
    orders: [
      { field: 'salesOrderNumber', headerName: 'Order Number' },
      { field: 'orderedDate', headerName: 'Order Date' },
      { field: 'totalInvoicesValue', headerName: 'Amount' },
    ],
  };

  if (error.main) return <ErrorPage errorMessage={error.main} isDarkMode={isDarkMode} showNote={true} showBackButton={false} />;

  return (
    <div
      className={`min-h-screen flex flex-col ${
        isDarkMode ? 'bg-gray-900 text-white' : 'bg-gray-100 text-gray-900'
      }`}
    >
      {/* Header */}
      <Header />

      {/* Main Content */}
      <div className="container mx-auto px-2 py-3 pb-8 flex-grow">
        <div className="flex flex-col md:flex-row gap-4">
          {/* Left Panel */}
          <div className="w-full md:w-1/3 flex flex-col gap-4">
            {/* Customer Details */}
            <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-2">
              <h2 className="bg-blue-500 dark:bg-blue-700 text-white px-2 py-1 rounded-t-lg font-semibold">
                CRM Details
              </h2>
              <div className="p-2">
                {loading.main ? (
                  <LoadingSpinner />
                ) : (
                  <table className="min-w-full">
                    <tbody>
                      {data.customerDetails.map((detail) => (
                        <tr key={detail.id} className="border-b border-gray-200 dark:border-gray-700">
                          <td className="px-2 py-1 font-medium">{detail.attribute}</td>
                          <td className="px-2 py-1">{detail.value}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>

          {/* Individual Details */}
          <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-2">
            <h2 className="bg-blue-500 dark:bg-blue-700 text-white px-2 py-1 rounded-t-lg font-semibold">
              Individual Details
            </h2>
            <div className="p-2">
              {loading.main ? (
                <LoadingSpinner />
              ) : (
                <table className="min-w-full">
                  <tbody>
                    {data.individualDetails.map((detail) => (
                      <tr key={detail.id} className="border-b border-gray-200 dark:border-gray-700">
                        <td className="px-2 py-1 font-medium">{detail.attribute}</td>
                        <td className="px-2 py-1">{detail.value}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>

          {/* Addresses */}
          <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-2">
            <h2 className="bg-blue-500 dark:bg-blue-700 text-white px-2 py-1 rounded-t-lg font-semibold">
              Addresses
            </h2>
            <div className="p-2">
              {loading.main ? (
                <LoadingSpinner />
              ) : (
                <table className="min-w-full">
                  <tbody>
                    {data.addresses.map((address) => (
                      <tr key={address.id} className="border-b border-gray-200 dark:border-gray-700">
                        <td className="px-2 py-1 font-medium">{address.attribute}</td>
                        <td className="px-2 py-1">{address.value}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>

          {/* Communications Personal */}
          <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-2">
            <h2 className="bg-blue-500 dark:bg-blue-700 text-white px-2 py-1 rounded-t-lg font-semibold">
              Communications Personal
            </h2>
            <div className="p-2">
              {loading.main ? (
                <LoadingSpinner />
              ) : (
                <table className="min-w-full">
                  <tbody>
                    {data.communications.map((comm) => (
                      <tr key={comm.id} className="border-b border-gray-200 dark:border-gray-700">
                        <td className="px-2 py-1 font-medium">{comm.attribute}</td>
                        <td className="px-2 py-1">{comm.value}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>

          {/* Regional */}
          <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-2">
            <h2 className="bg-blue-500 dark:bg-blue-700 text-white px-2 py-1 rounded-t-lg font-semibold">
              Regional Details
            </h2>
            <div className="p-2">
              {loading.main ? (
                <LoadingSpinner />
              ) : (
                <table className="min-w-full">
                  <tbody>
                    {data.regional.map((regional) => (
                      <tr key={regional.id} className="border-b border-gray-200 dark:border-gray-700">
                        <td className="px-2 py-1 font-medium">{regional.attribute}</td>
                        <td className="px-2 py-1">{regional.value}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>

          {/* Updates */}
          <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-2">
            <h2 className="bg-blue-500 dark:bg-blue-700 text-white px-2 py-1 rounded-t-lg font-semibold">
              Updates
            </h2>
            <div className="p-2">
              {loading.main ? (
                <LoadingSpinner />
              ) : (
                <table className="min-w-full">
                  <tbody>
                    {data.updates.map((update) => (
                      <tr key={update.id} className="border-b border-gray-200 dark:border-gray-700">
                        <td className="px-2 py-1 font-medium">{update.attribute}</td>
                        <td className="px-2 py-1">{update.value}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
          </div>


          {/* Right Panel */}
          <div className="w-full md:w-2/3 flex flex-col gap-6">
            {/* Top Cards */}
            <div className="flex flex-col md:flex-row gap-6">
              {/* Aftersales Bookings */}
              <div className="flex-1 bg-white dark:bg-gray-800 shadow rounded-lg p-4">
                <div className="flex items-center">
                  <div className="bg-green-500 text-white rounded-full h-12 w-12 flex items-center justify-center font-bold mr-4">
                    {formatCurrency(totals.bookings)}
                  </div>
                  <div>
                    <h3 className="text-lg font-semibold">Aftersales Bookings</h3>
                    <p className="text-sm text-gray-500 dark:text-gray-300">
                      {formatCurrency(totals.bookingsValue)}
                    </p>
                  </div>
                </div>
              </div>

              {/* Aftersales Invoices */}
              <div className="flex-1 bg-white dark:bg-gray-800 shadow rounded-lg p-4">
                <div className="flex items-center">
                  <div className="bg-green-500 text-white rounded-full h-12 w-12 flex items-center justify-center font-bold mr-4">
                    {totals.invoices}
                  </div>
                  <div>
                    <h3 className="text-lg font-semibold">Aftersales Invoices</h3>
                    <p className="text-sm text-gray-500 dark:text-gray-300">
                      {formatCurrency(totals.invoicesValue)}
                    </p>
                  </div>
                </div>
              </div>

              {/* Vehicle Orders */}
              <div className="flex-1 bg-white dark:bg-gray-800 shadow rounded-lg p-4">
                <div className="flex items-center">
                  <div className="bg-green-500 text-white rounded-full h-12 w-12 flex items-center justify-center font-bold mr-4">
                    {totals.vehicleOrders}
                  </div>
                  <div>
                    <h3 className="text-lg font-semibold">Vehicle Orders</h3>
                    <p className="text-sm text-gray-500 dark:text-gray-300">
                      {formatCurrency(totals.vehicleOrdersValue)}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* Tabs for Detailed Data */}
            <div className="bg-white dark:bg-gray-800 shadow rounded-lg">
              {/* Tabs Header */}
              <div className="flex border-b border-gray-200 dark:border-gray-700">
                <button
                  className={`py-2 px-4 text-sm font-medium ${
                    tabValue === 0
                      ? 'border-b-2 border-blue-500 text-blue-600 dark:text-blue-400'
                      : 'text-gray-500 hover:text-gray-700 dark:text-gray-300 dark:hover:text-white'
                  }`}
                  onClick={() => handleTabChange(0)}
                >
                  Vehicles
                </button>
                <button
                  className={`py-2 px-4 text-sm font-medium ${
                    tabValue === 1
                      ? 'border-b-2 border-blue-500 text-blue-600 dark:text-blue-400'
                      : 'text-gray-500 hover:text-gray-700 dark:text-gray-300 dark:hover:text-white'
                  }`}
                  onClick={() => handleTabChange(1)}
                >
                  Bookings
                </button>
                <button
                  className={`py-2 px-4 text-sm font-medium ${
                    tabValue === 2
                      ? 'border-b-2 border-blue-500 text-blue-600 dark:text-blue-400'
                      : 'text-gray-500 hover:text-gray-700 dark:text-gray-300 dark:hover:text-white'
                  }`}
                  onClick={() => handleTabChange(2)}
                >
                  Invoices
                </button>
                <button
                  className={`py-2 px-4 text-sm font-medium ${
                    tabValue === 3
                      ? 'border-b-2 border-blue-500 text-blue-600 dark:text-blue-400'
                      : 'text-gray-500 hover:text-gray-700 dark:text-gray-300 dark:hover:text-white'
                  }`}
                  onClick={() => handleTabChange(3)}
                >
                  Orders
                </button>
              </div>

              {/* Tabs Content */}
              <div className="p-4">
                {/* Vehicles Tab */}
                {tabValue === 0 && (
                  <>
                    {loading.vehicles ? (
                      <LoadingSpinner />
                    ) : error.vehicles ? (
                      <div className="text-red-500">{error.vehicles}</div>
                    ) : (
                      <div className="overflow-x-auto">
                        <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                          <thead className="bg-gray-50 dark:bg-gray-700">
                            <tr>
                              {headers.vehicles.map((header) => (
                                <th
                                  key={header.field}
                                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider"
                                >
                                  {header.headerName}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
                            {data.vehicles.length === 0 ? (
                              <tr>
                                <td
                                  colSpan={headers.vehicles.length}
                                  className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300 text-center"
                                >
                                  No data available
                                </td>
                              </tr>
                            ) : (
                              data.vehicles.map((vehicle) => (
                                <tr
                                  key={vehicle.id}
                                  className="hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer"
                                >
                                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-gray-100">
                                    {vehicle.relationship}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-gray-100">
                                    {trimName(vehicle.description, 40)}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-gray-100">
                                    {vehicle.licensePlate}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-gray-100">
                                    {vehicle.vin}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-gray-100">
                                    {vehicle.reference}
                                  </td>
                                </tr>
                              ))
                            )}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </>
                )}

                {/* Bookings Tab */}
                {tabValue === 1 && (
                  <>
                    {loading.bookings ? (
                      <LoadingSpinner />
                    ) : error.bookings ? (
                      <div className="text-red-500">{error.bookings}</div>
                    ) : (
                      <div className="overflow-x-auto">
                        <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                          <thead className="bg-gray-50 dark:bg-gray-700">
                            <tr>
                              {headers.bookings.map((header) => (
                                <th
                                  key={header.field}
                                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider"
                                >
                                  {header.headerName}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
                            {data.serviceBookings.length === 0 ? (
                              <tr>
                                <td
                                  colSpan={headers.bookings.length}
                                  className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300 text-center"
                                >
                                  No data available
                                </td>
                              </tr>
                            ) : (
                              data.serviceBookings.map((booking) => (
                                <tr
                                  key={booking.id}
                                  className="hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer"
                                >
                                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-gray-100">
                                    {booking.wipNumber}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-gray-100">
                                    {formatDate(booking.dateIn)}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-gray-100">
                                    {formatStatus(booking.bookingStatus)}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-gray-100">
                                    {formatCurrency(booking.bookingValue)}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-gray-100">
                                    {booking.vrm}
                                  </td>
                                </tr>
                              ))
                            )}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </>
                )}

                {/* Invoices Tab */}
                {tabValue === 2 && (
                  <>
                    {loading.invoices ? (
                      <LoadingSpinner />
                    ) : error.invoices ? (
                      <div className="text-red-500">{error.invoices}</div>
                    ) : (
                      <div className="overflow-x-auto">
                        <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                          <thead className="bg-gray-50 dark:bg-gray-700">
                            <tr>
                              {headers.invoices.map((header) => (
                                <th
                                  key={header.field}
                                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider"
                                >
                                  {header.headerName}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
                            {data.aftersalesInvoices.length === 0 ? (
                              <tr>
                                <td
                                  colSpan={headers.invoices.length}
                                  className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300 text-center"
                                >
                                  No data available
                                </td>
                              </tr>
                            ) : (
                              data.aftersalesInvoices.map((invoice) => (
                                <tr
                                  key={invoice.id}
                                  className="hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer"
                                >
                                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-gray-100">
                                    {invoice.invoiceNumber}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-gray-100">
                                    {formatDate(invoice.invoiceDate)}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-gray-100">
                                    {formatCurrency(invoice.invoiceValue)}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-gray-100">
                                    {invoice.wipNumber}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-gray-100">
                                    {invoice.vrm}
                                  </td>
                                </tr>
                              ))
                            )}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </>
                )}

                {/* Orders Tab */}
                {tabValue === 3 && (
                  <>
                    {loading.orders ? (
                      <LoadingSpinner />
                    ) : error.orders ? (
                      <div className="text-red-500">{error.orders}</div>
                    ) : (
                      <div className="overflow-x-auto">
                        <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                          <thead className="bg-gray-50 dark:bg-gray-700">
                            <tr>
                              {headers.orders.map((header) => (
                                <th
                                  key={header.field}
                                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider"
                                >
                                  {header.headerName}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
                            {data.vehicleOrders.length === 0 ? (
                              <tr>
                                <td
                                  colSpan={headers.orders.length}
                                  className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300 text-center"
                                >
                                  No data available
                                </td>
                              </tr>
                            ) : (
                              data.vehicleOrders.map((order) => (
                                <tr
                                  key={order.id}
                                  className="hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer"
                                >
                                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-gray-100">
                                    {order.salesOrderNumber}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-gray-100">
                                    {formatDate(order.orderedDate)}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-gray-100">
                                    {formatCurrency(order.totalInvoicesValue)}
                                  </td>
                                </tr>
                              ))
                            )}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Footer */}
      <footer className="fixed bottom-0 left-0 right-0 bg-gray-200 dark:bg-gray-700 text-center p-2 text-sm z-50">
        © {new Date().getFullYear()} AI-Assistant. All rights reserved.
      </footer>
    </div>
  );
};

export default CustomerContent;

